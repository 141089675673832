import { RxDashboard } from 'react-icons/rx'
import { AiOutlineUser } from 'react-icons/ai'
import { TbFileDollar } from 'react-icons/tb'
import { FiKey } from 'react-icons/fi'
import { FiLogOut } from 'react-icons/fi'

const NavList = [
    {
        label: "Dashboard", icon: RxDashboard, path: '/app/dashboard', active: false
    },
    {
        label: "My Subscription", icon: AiOutlineUser, path: '/app/subscription', active: false
    },
    {
        label: "Invoice", icon: TbFileDollar, path: '/app/invoices', active: false
    },
    {
        label: "Change Password", icon: FiKey, path: '/app/changepassword', active: true
    },
    {
        label: "Sign out", icon: FiLogOut, path: '/signin', active: false
    },
  ]

  export default NavList