import React, { useState, useRef, memo, useEffect } from 'react'
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import useStyles from './styles'
import { useMediaQuery } from 'react-responsive'
import 'react-alice-carousel/lib/alice-carousel.css'
import { BiMinus, BiPlus } from 'react-icons/bi'
import * as cx from 'classnames'

const LookingForSection = (props) => {
  const [openList, setOpenList] = useState([true, true, true, true, true])
  const classes = useStyles()
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const updateOpenList = (index) => {
    var tmpOpenList = [...openList]
    tmpOpenList[index] = !openList[index]
    setOpenList(tmpOpenList)
  }

  return (
    <Container className={classes.root}>
      <div className={classes.title}>Frequently Asked Questions</div>
      <div className={cx('center-center pb-40', classes.descTitle)}>Get answers to some of the most
        common questions about Wick Predict.</div>
      <div className='question-tab'>
          <Accordion className='pb-5'>
            <AccordionSummary
              expandIcon={openList[0] ? 
                <BiPlus onClick={() => updateOpenList(0)}/> :
                <BiMinus onClick={() => updateOpenList(0)} />}
                  onClick={(event) => {
                updateOpenList(0)
                }}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.questionTitle}
            >
              <div className={cx('center-between',classes.quiz)} onClick={() => { updateOpenList(0) }}>
                Why choose Wick Predict?</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.questionDesc}>
                <span className={classes.questionDescBold}>Wick Predict helps all traders, across all platforms,
                  recognize technical analysis patterns on almost any candlestick chart.</span>
                Whether viewing a chart on a Bloomberg (equities), TradingView
                (crypto and equities), E-Trade, Binance (crypto) or any other
                platform that has candlestick charting, Wick Predict makes
                recognizing candlestick chart patterns easier.<br />
                <br />
                Wick Predict is also an educational tool for aspiring traders
                that wish to learn technical analysis trading patterns through
                memorization. As a virtual set of technical analysis flashcards,
                you can memorize these valuable patterns and Wick Predict can help.
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className='pb-5'>
            <AccordionSummary
              expandIcon={openList[1] ? 
                <BiPlus onClick={() => updateOpenList(1)}/> :
                <BiMinus onClick={() => updateOpenList(1)} />}
                  onClick={(event) => {
                updateOpenList(1)
                }}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.questionTitle}
            >
              <div className={cx('center-between',classes.quiz)} onClick={() => { updateOpenList(0) }}>
                What does Wick Predict do?</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.questionDesc}>
                <div className={classes.questionDescBold}>Candle Mode - Pattern Recognition</div>
                <div>
                  Wick Predict is a dynamic and real-time screen snippet tool engineered to
                  recognize an extensive database of over 100 industry-recognized
                  technical analysis candlestick patterns.<br />
                  <br />
                  Initiate “Candle Mode” with the chart candles icon. Move the snippet
                  screen on Wick Predict’s interface over almost any candlestick chart
                  on your desktop; Wick Predict will instantly deliver a set of the closest
                  pattern matches along with a ranked probability of the degree of matching.<br />
                  <br />
                  Each pattern is accompanied by a virtual flashcard with Direction, Characteristics
                  and Notes. The tool can be dragged over any time frame and will recognize
                  candlestick patterns of 1-5 candles in “Candlestick Mode” and longer
                  patterns of 5-15 candles in “Chart Mode”.
                </div>
                <div className={classes.questionDescBold}>Learning Mode - Database Search & Virtual Flashcards</div>
                <div>Turn ON “Learning Mode” with the lightbulb icon and Wick Predict
                  is a virtual set of technical analysis flashcards and a searchable database
                  for quickly summoning a specific pattern by name. You can also filter the
                  results by Bullish or Bearish patterns.</div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className='pb-5'>
            <AccordionSummary
              expandIcon={openList[2] ? 
                <BiPlus onClick={() => updateOpenList(2)}/> :
                <BiMinus onClick={() => updateOpenList(2)} />}
                  onClick={(event) => {
                updateOpenList(2)
                }}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.questionTitle}
            >
              <div className={cx('center-between',classes.quiz)} onClick={() => { updateOpenList(0) }}>
                Who is Wick Predict for? Can I use this as a beginner? </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.questionDesc}>
                <span className={classes.questionDescBold}>Wick Predict is for anyone
                  interested in learning (memorizing) technical analysis candlestick trading patterns.</span>
                Wick Predict works for students, investors and traders of all experience levels.
                The learning curve in Technical Analysis is steep. Wick Predict helps smooth-out
                that learning curve and make learning TA patterns fun.<br />
                <br />
                Both beginner and experienced professional traders appreciate having
                a tool like Wick Predict in their toolbox. Wick Predict is perfect
                for locating candlestick patterns and longer chart formations to
                find new opportunities or verify what you are looking at on your desktop chart.
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className='pb-5'>
            <AccordionSummary
              expandIcon={openList[3] ? 
                <BiPlus onClick={() => updateOpenList(3)}/> :
                <BiMinus onClick={() => updateOpenList(3)} />}
                  onClick={(event) => {
                updateOpenList(3)
                }}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.questionTitle}
            >
              <div className={cx('center-between',classes.quiz)} onClick={() => { updateOpenList(0) }}>
                How much does Wick Predict cost? </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.questionDesc}>
                If you’re new to Wick Predict,
                you can try it FREE for 1 week. Click the Free Trial sign-up to get started.
                You will be able to immediately download Wick Predict and begin using the program within minutes.<br />
                <br />
                After the 1-week trial has expired, your membership will renew at $9.98/month.
                You can save 35% per year by switching to an annual subscription at a rate of
                $78.98/year! Just choose annual subscription option when your trial expires or during checkout.
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className='pb-5'>
            <AccordionSummary
              expandIcon={openList[4] ? 
                <BiPlus onClick={() => updateOpenList(4)}/> :
                <BiMinus onClick={() => updateOpenList(4)} />}
                  onClick={(event) => {
                updateOpenList(4)
                }}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.questionTitle}
            >
              <div className={cx('center-between',classes.quiz)} onClick={() => { updateOpenList(0) }}>
                Can I cancel my Wick Predict subscription?</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.questionDesc}>
                You can cancel your Wick Predict subscription at any time. Just email
                your request to support@wickpredict.io.
              </div>
            </AccordionDetails>
          </Accordion>
      </div>
    </Container>
  )
}

export default LookingForSection