import React, { useState, memo } from 'react'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { useMediaQuery } from 'react-responsive'
import binanceEarn1 from 'assets/images/images/binance-earn-1.png'
import logo_video from 'assets/images/images/logo_video.png'
import play_icon from 'assets/images/Icon/play.svg'
import { IconButton } from '@material-ui/core'

const LookingForSectionThree = (props) => {
  const classes = useStyles()
  const isMobile = useMediaQuery({ maxWidth: 600 })

  return (
    <Container className={classes.root}>
      <div className={classes.title}>Any Chart, Any Class, Any Time Frame</div>
      <Grid container spacing={3} className="center-between">
        <Grid item xs={isMobile ? 12 : 6} className={classes.description}>
          <div className='pb-20'>
            <span className={classes.descTitle}>Wick Predict is engineered
              to recognize candlestick patterns on any desktop screen and
              across asset classes.</span> The pattern detection of Wick Predict
            looks for candlesticks on your desktop and it doesn’t care what
            platform is rendering that candlestick chart.
          </div>
          <div className='pb-20'>
            <span className={classes.descTitle}>Wick Predict’s database is robust</span>
            &nbsp;and updated frequently with almost every pattern that is recognized
            by traders across all asset classes. The patterns to the right are
            just a few of the most industry recognized patterns.
          </div>
          <div className='pb-20'>
            Download your FREE Trial of Wick Predict and within minutes you’ll
            see how versatile and valuable Wick Predict can be for finding
            patterns to help you trade and for learning to recognize patterns on your own.
          </div>
        </Grid>
        <Grid item container xs={isMobile ? 12 : 6}>
          {
            props.detailList.map((ele, index) => {
              console.log("image ele:", ele)
              return <Grid item xs={4} onClick={() => { props.showImage(index) }}
                style={{ cursor: "pointer", padding: '5px' }}>
                <div className={classes.imageCover}>
                  <img src={require('assets/images/images/detail_0' + (index + 1).toString() + '.png')}
                    style={{ width: "-webkit-fill-available" }} alt='' />
                  <div className={classes.imgLogoCover}>
                    <img className={classes.imgCoverLogo}
                      src={require('assets/images/images/cover_0' + (index + 1).toString() + '.png')} />
                  </div>
                </div>
              </Grid>
            })
          }
        </Grid>

      </Grid>
    </Container>
  )
}

export default LookingForSectionThree
