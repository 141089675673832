import React, { useState, useRef, memo } from 'react'
import ReactPlayer from 'react-player'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { useMediaQuery } from 'react-responsive'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Button } from '@material-ui/core'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import priceTop from 'assets/images/images/price_top.png'
import { useHistory } from 'react-router'


const LookingForSection = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const handleMoveToStart = (value) => {
    props.moveToStart(value)
  }

  return (
    <Container className={classes.root}>
      <div className={classes.title}>Pricing</div>
      <Grid container spacing={3} className="center-center">
        <div className={classes.managerCard}>
          <div className='center-center flex-column'><div className={classes.managerName}>MONTHLY</div>
            <div>&nbsp;</div></div>
          <div className={classes.managerPrice}>$9.98</div>
          <div className={classes.managerDetail}>Billed Monthly.<br />
            7 Day Free Trial.<br />
            30-day money-back guarantee.</div>
          <Button className={classes.home_btn}
           onClick={()=>handleMoveToStart()}
          >{"Get started"}&nbsp;<FaArrowRight /></Button>
        </div>
        <div className={classes.managerCard}>
          <div className={classes.priceTop}>
            <img src={priceTop} alt="" />
          </div>
          <div className='center-center flex-column'><div className={classes.managerName}>ANNUAL</div>
            <div className={classes.managerSubPrice}>(Save 35%)</div></div>
          <div className={classes.managerPrice}>$78.98</div>
          <div className={classes.managerDetail}>Billed Annually.<br />
            7 Day Free Trial.<br />
            30-day money-back guarantee.</div>
          <Button className={classes.home_btn}
          onClick={()=>handleMoveToStart("year")}
          >{"Get started"}&nbsp;<FaArrowRight /></Button>
        </div>
      </Grid>
    </Container>
  )
}

export default LookingForSection
