import React, { useState, useMemo, useEffect } from 'react'
import {
    Grid,
    Button,
    IconButton,
    LinearProgress,
    CircularProgress
} from '@material-ui/core'
import useStyles from './styles'
import * as cx from 'classnames'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { IoMdAlert } from 'react-icons/io'
import { FaRegCopy } from 'react-icons/fa'
import { AiFillWindows } from 'react-icons/ai'
import { AiFillApple } from 'react-icons/ai'
import { SiLinux } from 'react-icons/si'
import { IoIosArrowForward } from 'react-icons/io'
import { AiOutlineDownload } from 'react-icons/ai'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { subscriptionInfoSelector } from 'redux/modules/subscribe/selectors'
import { createStructuredSelector } from 'reselect'
import dashboard_a from 'assets/images/images/dashboard_a.png'
import {
    getSubscriptionInfo,
} from 'redux/modules/subscribe/actions'
import moment from 'moment'
import { roles } from 'helpers/AppConfig'
import { useHistory } from 'react-router'
import { useMediaQuery } from 'react-responsive'
import xml2js from 'xml2js'

const parser = new xml2js.Parser()



const Dashboard = (props) => {
    const classes = useStyles()
    const [subscriptionInfo, setSubscriptionInfo] = useState({})
    const [copied, setCopied] = useState(false)
    const [filename, setFileName] = useState('')
    const history = useHistory()
    const isMobile = useMediaQuery({ maxWidth: 600 })
    const url = 'https://wickpredict.s3.amazonaws.com/'
    useEffect(() => {
        if (props.currentUser != null)
            props.getSubscriptionInfo({ body: { 'userId': props.currentUser.id } })
    }, [props, props.currentUser])

    useEffect(() => {
        setSubscriptionInfo(props.subscriptionInfo ?? {})
    }, [props.subscriptionInfo])

    useEffect(() => {
        getXMLfromURL(url)
        }, [url])


    
    const getXMLfromURL = async (url) => {
	try {
		const response = await fetch(url)
 
		const content = await response.text()
		const data = await parser.parseStringPromise(content)
   
        for(let i = 0; i < data.ListBucketResult.Contents.length; i++) {
            if(data.ListBucketResult.Contents[i].Key[0].includes('.exe')){
                console.log(data.ListBucketResult.Contents[i].Key[0])
     
                setFileName(data.ListBucketResult.Contents[i].Key[0])
            }
        }
		return data
	} catch (e) {
		console.log(e)
	}
}


    const downloadFile = () => {
        
      window.location.href = "https://wickpredict.s3.amazonaws.com/"+filename
    }
    return (
        <>
            {props.subscriptionInfo.planType == undefined ? <div className='mt-40 center-column loading-appboard'>
                <CircularProgress />
                <div className='loading-text pt-40'>Loading</div></div> :
                <div>
                    <div className={classes.cardSummary}>
                        {props.subscriptionInfo.planType == roles[0] ?
                            !isMobile ? <Grid container>
                                <Grid item md={7} xs={12} className="between-start">
                                    <div className={cx(classes.cardTitle)}>Miss WickPredict?</div>
                                    <div className={cx(classes.cardTitle, 'pb-30')}>Get a subscription</div>
                                    {
                                        props.currentUser.status == 'active' ? <Button className={classes.home_btn}
                                            onClick={() => { history.push('subscriptionplans') }}
                                        >Buy a New Subscription</Button> : <Button className={classes.home_btn}
                                        >Contact with support</Button>
                                    }
                                </Grid>
                                <Grid item md={5} xs={12} className="center-center">
                                    
                                </Grid>
                            </Grid>
                                : <Grid><Grid item md={7} xs={12} className="between-start">
                                    <div className={cx(classes.cardTitle)}>Miss Wickpredict?</div>
                                    <div className={cx(classes.cardTitle, 'pb-30')}>Get a subscription</div>
                                    {
                                        props.currentUser.status == 'active' ? <Button className={classes.home_btn}
                                            onClick={() => { history.push('subscriptionplans') }}
                                        >Buy a New Subscription</Button> : <Button className={classes.home_btn}
                                        >Contact with support</Button>
                                    }
                                </Grid></Grid>
                            : !isMobile ? <Grid container>
                                <Grid item md={7} xs={12} className="between-start">
                                    <div className={cx(classes.cardTitle, 'pb-30')}>Dashboard</div>
                                    <div className={classes.cardContent}>
                                        <div>Current Plan: {
                                            subscriptionInfo.planType == roles[1]
                                                ? "$9.98 per month" : "$78.98 per year"}</div>
                                        <div>Next payment will be taken on {
                                            subscriptionInfo.current_period_end == undefined ?
                                                moment().format("MMM Do YYYY")
                                                : moment.unix(subscriptionInfo.current_period_end)
                                                    .format("MMM Do YYYY")}</div>
                                    </div>
                                    <div className={cx(classes.cardDesc, 'center-start', 'pt-30')}>
                                        <IoMdAlert size={20} />
                                        <span className='pl-10'>Activation code for one device</span></div>
                                </Grid>
                                <Grid item md={5} xs={12} className="center-center">
                                   
                                </Grid>
                            </Grid> 
                            
                            
                            : <Grid container>
                                <Grid item md={7} xs={12} className="between-start">
                                    <div className={cx(classes.cardTitle, 'pb-20')}>Dashboard</div>
                                    <div className={classes.cardContent}>
                                        <div className='pb-10'>Current Plan: {
                                            subscriptionInfo.planType == roles[1]
                                                ? "$9.98 per month" : "$78.98 per year"}</div>
                                        <div>Next payment will be taken on {
                                            subscriptionInfo.current_period_end == undefined ?
                                                moment().format("MMM Do YYYY")
                                                : moment.unix(subscriptionInfo.current_period_end)
                                                    .format("MMM Do YYYY")}</div>
                                    </div>
                                    <div className={cx(classes.cardDesc, 'center-start', 'pt-20')}>
                                        <IoMdAlert size={20} />
                                        <span className='pl-10'>Activation code for one device</span></div>
                                </Grid>
                            </Grid>}
                    </div>
                    {props.subscriptionInfo.planType == roles[0] ? <></> : <Grid container>
                        <Grid item md={6} xs={12}>
                            <div className={classes.cardCode}>
                                <div className="pl-10 pb-10">ACTIVATION CODE</div>
                                <div className={cx(classes.codeBox, 'center-between')}>
                                    <div>{subscriptionInfo.activationCode}</div>
                                    {copied ? <div className={cx('pr-10', classes.copiedText)}>Copied</div>
                                        : <IconButton onClick={() => {
                                            navigator.clipboard.writeText(subscriptionInfo.activationCode)
                                            setCopied(true)
                                            setTimeout(() => {
                                                setCopied(false)
                                            }, 2000)
                                        }
                                        }><FaRegCopy />
                                        </IconButton>}
                                </div>
                                <div className="pl-10 pt-10 pb-10">Download Apps</div>
                                <div className='pl-20 pb-20'>
                                    {filename.trim() !='' ?
                                    <div className='center-between'>
                                        <div className='center-center'>
                                            <AiFillWindows /><span className='pl-10'>Windows</span>
                                        </div>

                                        <IconButton onClick={downloadFile}><AiOutlineDownload /></IconButton>
                                    </div>
                                    :''
                                    }
                                    {/* <div className='center-between'>
                                        <div className='center-center'>
                                            <AiFillApple /><span className='pl-10'>Mac</span>
                                        </div>
                                        <IconButton onClick={downloadFile}><AiOutlineDownload /></IconButton>
                                    </div>
                                    <div className='center-between'>
                                        <div className='center-center'>
                                            <SiLinux /><span className='pl-10'>Linux</span>
                                        </div>
                                        <IconButton onClick={downloadFile}><AiOutlineDownload /></IconButton>
                                    </div> */}
                                </div>
                            </div>
                        </Grid>
                    </Grid>}
                </div>
            }
        </>
    )
}

Dashboard.propTypes = {
    currentUser: PropTypes.any,
    getSubscriptionInfo: PropTypes.func,
}

const actions = {
    getSubscriptionInfo
}

const selector = createStructuredSelector({
    currentUser: currentUserSelector,
    subscriptionInfo: subscriptionInfoSelector
})

export default compose(connect(selector, actions))(Dashboard)