import React, { useState, useEffect } from 'react'
import { Mobile, Default } from 'containers/ResponseLayout'
import { Header, MobileHeader } from 'containers/Header'
import { RegisterStart } from 'containers/RegisterStart'
import { Footer, MobileFooter } from 'containers/Footer'
import { BackgroundLine } from 'components/BackgroundLine'
import { useLocation } from 'react-router-dom'
import 'react-modal-video/scss/modal-video.scss'

const StartRegister = () => {
  const location = useLocation()
  const [isLandingHeader, setIsLandingHeader] = useState(true)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    const len = window.location.search.length
    const value = window.scrollY > 50 ? false : true
    len === 0 && setIsLandingHeader(value)
  }

  return (
    <>
      <Mobile>
        <MobileHeader isLandingPage={isLandingHeader} />
        <div className='home-pattern-section'>
            <RegisterStart/>
            </div>
      </Mobile>

      <Default>
        <BackgroundLine>
            <Header isLandingPage={isLandingHeader} />
            <div className='home-pattern-section'>
            <RegisterStart/>
            </div>
          <Footer />
        </BackgroundLine>
      </Default>
    </>
  )
}

export default StartRegister
