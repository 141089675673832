import { combineReducers } from 'redux'

import global from './modules/global/reducers'
import auth from './modules/auth/reducers'
import subscribe from './modules/subscribe/reducers'
import user from './modules/user/reducers'

export default combineReducers({
  global,
  auth,
  subscribe,
  user,
})
