import React, { useState, useRef, memo } from 'react'
import ReactPlayer from 'react-player'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { useMediaQuery } from 'react-responsive'
import avatar0 from 'assets/images/avatar/avatar-0.png'
import avatar1 from 'assets/images/avatar/avatar-1.png'
import avatar2 from 'assets/images/avatar/avatar-2.png'
import 'react-alice-carousel/lib/alice-carousel.css'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Slider from "react-slick"

const LookingForSection = (props) => {
  const classes = useStyles()
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const items = [
    <div className='center-center'>
      <div className={classes.managerCard}>
        <div className='center-center'><img src={avatar0} alt="" className={classes.managerAvatar} /></div>
        <div className={classes.managerName}>Roe Root</div>
        <div className={classes.managerStatus}>Investment Advisor</div>
        <div className={classes.managerDetail}>“I use Wick Predict to verify what I’m
          looking at. It’s a really nice tool for
          spotting patterns.”</div>
      </div>
    </div>,
    <div className='center-center'>
      <div className={classes.managerCard}>
        <div className='center-center'><img src={avatar1} alt="" className={classes.managerAvatar} /></div>
        <div className={classes.managerName}>Ross Taylor</div>
        <div className={classes.managerStatus}>Independent Crypto Trader</div>
        <div className={classes.managerDetail}>“I’ve never seen anything like Wick
          Predict. It’s a brilliant idea and a useful
          tool for any trader.”</div>
      </div>
    </div>,
    <div className='center-center'>
      <div className={classes.managerCard}>
        <div className='center-center'><img src={avatar2} alt="" className={classes.managerAvatar} /></div>
        <div className={classes.managerName}>Shane Watson</div>
        <div className={classes.managerStatus}>Professional Equities Trader</div>
        <div className={classes.managerDetail}>“Simple and yet sophisticated,
          Wick Predict should be a staple
          in the bag of every trader.”</div>
      </div>
    </div>,
    <div className='center-center'>
      <div className={classes.managerCard}>
        <div className='center-center'><img src={avatar0} alt="" className={classes.managerAvatar} /></div>
        <div className={classes.managerName}>Roe Root</div>
        <div className={classes.managerStatus}>Investment Advisor</div>
        <div className={classes.managerDetail}>“I use Wick Predict to verify what I’m
          looking at. It’s a really nice tool for
          spotting patterns.”</div>
      </div>
    </div>,
    <div className='center-center'>
      <div className={classes.managerCard}>
        <div className='center-center'><img src={avatar1} alt="" className={classes.managerAvatar} /></div>
        <div className={classes.managerName}>Ross Taylor</div>
        <div className={classes.managerStatus}>Independent Crypto Trader</div>
        <div className={classes.managerDetail}>“I’ve never seen anything like Wick
          Predict. It’s a brilliant idea and a useful
          tool for any trader.”</div>
      </div>
    </div>,
    <div className='center-center'>
      <div className={classes.managerCard}>
        <div className='center-center'><img src={avatar2} alt="" className={classes.managerAvatar} /></div>
        <div className={classes.managerName}>Shane Watson</div>
        <div className={classes.managerStatus}>Professional Equities Trader</div>
        <div className={classes.managerDetail}>“Simple and yet sophisticated,
          Wick Predict should be a staple
          in the bag of every trader.”</div>
      </div>
    </div>,
  ]

  return (
    <Container className={classes.root}>
      <div className={classes.title}>Real Talk from Real Users</div>
      <Grid container className="center-between">
        <Grid item xs={12}>
          <Slider
            nextArrow={<FaArrowRight color='white' />}
            prevArrow={<FaArrowLeft color='white' />}
            dots={false}
            slidesToShow={isMobile ? 1 : 3}
            slidesToScroll={2}
            autoplay={true}
          >
            {items}
          </Slider>
        </Grid>
      </Grid>
    </Container>
  )
}

export default LookingForSection
