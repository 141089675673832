import { makeStyles } from "@material-ui/styles"
export default makeStyles(theme => ({
  title: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '38px',
    lineHeight: '40px',
    color: '#009348',
    paddingBottom: '10px'
  },
  subTitle: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#333333',
  },
  subDivider: {
    borderTop: '1px solid lightgrey'
  },
  content: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'center',
    lineHeight: '30px',
    color: '#333333',
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '21px',
    }
  },
  subContent: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '30px',
    color: '#333333',
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '21px',
    }
  },
  managerCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 0px 50px',
    gap: '25px',
    width: '330px',
    height: '377px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    marginLeft: '5px',
    marginRight: '5px',
    marginTop: '15px',
    marginBottom: '15px',
    position: 'relative',
  },
  priceTop: {
    position: 'absolute',
    top: '-6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  managerName: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '36px',
    lineHeight: '38px',
    /* identical to box height */
    textAlign: 'center',
    /* Main color */
    color: '#009348',
    '@media only screen and (max-width: 600px)': {
      fontSize: '26px',
      lineHeight: '39px',
    }
  },
  managerSubPrice: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    /* identical to box height */
    textAlign: 'center',
    /* Main color */
    color: '#333333',
    paddingTop: '10px'
  },
  managerPrice: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '36px',
    lineHeight: '34px',
    /* identical to box height */
    textAlign: 'center',
    /* Main color */
    color: '#333333',
    '@media only screen and (max-width: 600px)': {
      fontSize: '26px',
      lineHeight: '39px',
    }
  },
  unButton:{
    fontSize: '20px',
    lineHeight: '27px',
    textTransform: 'capitalize',
    color:'#333333',
    height:'50px',
    fontWeight:'500'
  },
  home_btn: {
    background: '#009348',
    boxShadow: '0px 0px 4px',
    border: '1px solid #009348',
    borderRadius: '4px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    // font-weight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '7px',
    paddingBottom: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'white',
      color: '#009348',
    },
  },
  planCover:{
    position:'relative'
  },
  disablePlan:{
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    background: 'transparent',
  }
}))