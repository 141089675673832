export const CREATE_STRIPE_CUSTOMER = 'CREATE_STRIPE_CUSTOMER'
export const CREATE_STRIPE_SUBSCRIPTION = 'CREATE_STRIPE_SUBSCRIPTION'
export const UPDATE_STRIPE_SUBSCRIPTION = 'UPDATE_STRIPE_SUBSCRIPTION'
export const GET_ALL_STRIPE_SUBSCRIPTIONS = 'GET_ALL_STRIPE_SUBSCRIPTIONS'
export const CANCEL_STRIPE_SUBSCRIPTION = 'CANCEL_STRIPE_SUBSCRIPTION'
export const GET_SUBSCRIPTION_INFO = 'GET_SUBSCRIPTION_INFO'
export const GET_SUBSCRIBE_DETAIL = 'GET_SUBSCRIBE_DETAIL'
export const CARD_UPDATE_INIT = 'CARD_UPDATE_INIT'
export const CARD_UPDATE_FINAL = 'CARD_UPDATE_FINAL'
export const GET_INVOICES = 'GET_INVOICES'
export const GET_ADMIN_INVOICES = 'GET_ADMIN_INVOICES'

export const CREATE_PAYPAL_SUBSCRIPTION = 'CREATE_PAYPAL_SUBSCRIPTION'
export const UPDATE_PAYPAL_SUBSCRIPTION = 'UPDATE_PAYPAL_SUBSCRIPTION'
export const GET_ALL_PAYPAL_SUBSCRIPTIONS = 'GET_ALL_PAYPAL_SUBSCRIPTIONS'
export const CANCEL_PAYPAL_SUBSCRIPTION = 'CANCEL_PAYPAL_SUBSCRIPTION'
export const CHECK_COUPON_VALIDATION = 'CHECK_COUPON_VALIDATION'
