import React, { useState, useEffect } from 'react'
import { Mobile, Default } from 'containers/ResponseLayout'
import { Header, MobileHeader } from 'containers/Header'
import { Footer, MobileFooter } from 'containers/Footer'
import { BackgroundLine } from 'components/BackgroundLine'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { SignInForm } from '../../containers/SignIn/SignInForm'

const SignIn = () => {
    const location = useLocation()
    const [isLandingHeader, setIsLandingHeader] = useState(true)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    const handleScroll = () => {
        const len = window.location.search.length
        const value = window.scrollY > 50 ? false : true
        len === 0 && setIsLandingHeader(value)
    }

    return (
        <>
        <Mobile>
            <MobileHeader isLandingPage={isLandingHeader} />
            <div className='home-detail-section pt-20'>
                <SignInForm />
            </div>
            <MobileFooter />
        </Mobile>

        <Default>
            <BackgroundLine>
                <Header isLandingPage={isLandingHeader} />
                <div className='home-auth-setion'>
                    <SignInForm />
                </div>
            </BackgroundLine>
            <div className='fixed-bottom'>
                    <Footer/>
                </div>
        </Default>
    </>
    )
}

export default SignIn
