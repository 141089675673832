import { makeStyles } from "@material-ui/styles"
export default makeStyles(theme => ({
  title: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '38px',
    lineHeight: '40px',
    color: '#009348',
    '@media only screen and (max-width: 600px)': {
      fontSize: '32px',
    }
  },
  subDivider: {
    borderTop: '1px solid lightgrey'
  },
  invoiceTbl: {
    backgroundColor: 'transparent'
  },
  posAlert: {
    position: 'absolute',
    right: '9px',
    top: '13px'
  },
  home_btn: {
    width: '100%',
    height: '50px',
    background: '#009348',
    boxShadow: '0px 0px 4px',
    border: '1px solid #009348',
    borderRadius: '4px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    // font-weight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '7px',
    paddingBottom: '5px',
    '&:hover': {
      background: 'white',
      color: '#009348',
    },
  },
  buttonProgress: {
    color: 'white'
  },

}))