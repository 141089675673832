import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { checkAccountVerification } from 'redux/modules/auth/actions'
import { setResetPasswordToken } from 'redux/modules/global/actions'
import PropTypes from 'prop-types'
import NewPasswordForm from '../../containers/NewPassword/NewPasswordForm/NewPasswordForm'
import { Mobile, Default } from 'containers/ResponseLayout'
import { Header, MobileHeader } from 'containers/Header'
import { Footer, MobileFooter } from 'containers/Footer'
import { BackgroundLine } from 'components/BackgroundLine'
import { Helmet } from 'react-helmet'

const Callback = ({
  checkAccountVerification,
  setResetPasswordToken,
}) => {
  const location = useLocation()
  const history = useHistory()

  const [isLandingHeader, setIsLandingHeader] = useState(true)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    const len = window.location.search.length
    const value = window.scrollY > 50 ? false : true
    len === 0 && setIsLandingHeader(value)
  }

  useEffect(() => {
    const doCheckAccountVerification = async (token) => {
      await checkAccountVerification({
        token,
        success: ({ data }) => {
          history.push('/')
        },
        fail: (err) => {
          history.push('/')
        }
      })
    }
    const init = () => {
      const params = new URLSearchParams(location.search)
      const arg_callbackMode = params.get('mode')
      if (arg_callbackMode === 'accountVerify') {
        const token = params.get('token')
        doCheckAccountVerification(token)
      } else if (arg_callbackMode === 'forgotPassword') {
        const token = params.get('token')
        setResetPasswordToken(token)
      }
    }
    init()
  }, [location, history, checkAccountVerification, setResetPasswordToken])

  return (
    <>
      <Mobile>
        <MobileHeader isLandingPage={isLandingHeader} />
        <div className='home-detail-section'>
            <NewPasswordForm />
        </div>
      </Mobile>

      <Default>
        <BackgroundLine>
          <Header isLandingPage={isLandingHeader} />
          <div className='home-auth-setion'>
            <NewPasswordForm />
          </div>
        </BackgroundLine>
        <div className='fixed-bottom'>
          <Footer />
        </div>
      </Default>
    </>
  )
}

Callback.propTypes = {
  checkAccountVerification: PropTypes.any,
  setResetPasswordToken: PropTypes.func,
}

const actions = {
  checkAccountVerification,
  setResetPasswordToken,
}

export default compose(connect(null, actions))(Callback)
