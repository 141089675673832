import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    // background: 'linear-gradient(165deg, #0052FF 0%, #FF00D0 100%)',
    background:'linear-gradient(180deg, #009348 0%, #7E9300 100%)',
    height: '380px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16.41px',
    paddingLeft: theme.spacing(6),
    '@media only screen and (max-width: 600px)': {
      paddingLeft: theme.spacing(3),
    }
  },
  footerCover:{
    maxWidth:'1280px',
    '@media only screen and (min-width: 1280px)': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      width:'100%'
    }
  },
  image: {
    userSelect: 'none',
    marginTop: theme.spacing(1),
  },
  bold: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '30px',
    marginBottom: '20px',
    cursor:'pointer',
  },
  home_btn:{
    background: '#FFFFFF',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: '4px',
    border: '1px solid #009348',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#009348',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '7px',
    paddingBottom: '5px',
    '&:hover': {
      background:'white',
      color:'#009348',
    },
  },
  normal: {
    marginBottom: theme.spacing(2),
    fontWeight: '400',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      color: '#DEDEDE',
      cursor: 'pointer',
      textDecoration: 'underline',
    }
  },
  footerBottom: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    /* identical to box height */
    color: 'rgba(255, 255, 255, 0.75)',
  },
  centerStartColumn:{
    display: 'flex',
    flexDirection: 'column',
    alignItems:'flex-start',
    justifyContent:'center',
    fontSize: '18px',
    lineHeight: '30px',
    fontWeight:'500'
  }
}))
