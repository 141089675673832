import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(6),
    '@media only screen and (max-width: 600px)': {
      paddingLeft: theme.spacing(3),
    }
  },
  home_btn:{
    background: '#009348',
    boxShadow: '0px 0px 4px',
    border: '1px solid #009348',
    borderRadius: '4px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    // font-weight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '7px',
    paddingBottom: '5px',
    '&:hover': {
      background:'white',
      color:'#009348',
    },
  },

  header: {
    background: '#009348',
    border: '1px solid #009348',
    color: 'white',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    fontFamily: 'Gotham',
    textTransform: 'none',
    height: '40px',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    marginLeft: theme.spacing(4),
    
  },
  title0: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '40px',
    lineHeight: '48px',
    /* identical to box height */
    /* Main color */
    color: '#333333'
  },
  title: {
    fontFamily: 'Gotham',
    color: '#000000',
    fontWeight: '400',
    fontSize: '40px',
    lineHeight: '40px',
    marginBottom: theme.spacing(3),
    // width: '400px',
    '@media only screen and (max-width: 600px)': {
      fontSize: '24px',
      lineHeight: '36px',
    }
  },
  title1:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '36px',
    color: '#009348;',
    '@media only screen and (max-width: 992px)': {
      fontSize: '16px',
      lineHeight: '24px',
    }
  },
  detailDetect:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    color: '#333333',
    marginBottom:'15px',
    '@media only screen and (max-width: 992px)': {
      fontSize: '16px',
      lineHeight: '24px',
    }
  },
  detailItem: {
    marginBottom: theme.spacing(3),
    width: '390px',
    '@media only screen and (max-width: 992px)': {
      width: '100%',
    }
  },
  detailTitle: {
    display: 'flex',
    color: '#333333',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '17px',
    fontFamily:'Roboto',
    marginBottom: theme.spacing(1),
    '@media only screen and (max-width: 600px)': {
      width: '100%',
      marginBottom: theme.spacing(1.5),
    }
  },
  detailDescription: {
    color: '#333333',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily:'Roboto'
  },
  checkIcon: {
    marginRight: theme.spacing(1),
    userSelect: 'none',
    width: '17px',
    height: '17px',
  }
}))
