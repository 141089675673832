import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    //width: '600px',
    height: '40px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  rootSimple: {
    display: 'flex',
    width: '600px',
    height: '40px',
    marginBottom: theme.spacing(2),

    '@media only screen and (min-width: 601px) and (max-width: 992px)': {
      width: 'auto',
      minWidth: '200px',
      marginTop: theme.spacing(2),
    }
  },
  toggleButton: {
    color: '#333333 !important',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    transition: 'color 0.3s ease',
  },
  toggleOutlineButton: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    transition: 'color 0.3s ease',
    color: '#333333 !important',
    height: 40,
    '&:hover': {
      color: 'white !important',
      background: 'linear-gradient(165deg, #0052FF 0%, #FF00D0 100%) border-box',
    },
    '&.Mui-selected': {
      border: '1px solid transparent',
      background:
        'linear-gradient(#fff,#fff) padding-box, linear-gradient(165deg, #0052FF 0%, #FF00D0 100%) border-box',
      '&:hover': {
        color: 'white !important',
        background: 'linear-gradient(165deg, #0052FF 0%, #FF00D0 100%) border-box',
      },
    }
  },
  searchButton: {
    backgroundColor: '#0052FF',
    height: '40px',
    color: 'white',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#4C86FE',
    }
  },
  group: {
    height: '40px',
    backgroundColor: 'white',
  },
  textField: {
    height: '40px',
    width: '365px',
    border: '1px solid #DDDDDD',
    borderLeft: 'none',
  },
  ipadSearchbar: {
    width: '200px',
    borderLeft: '1px solid #DDDDDD',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  soloTextField: {
    height: '40px',
    minWidth: '365px',
    width: '100%',
    border: '1px solid #DDDDDD',
    borderRadius: '4px 0px 0px 4px',
    borderRight: 'none',
    background: 'white',

    '@media only screen and (min-width: 601px) and (max-width: 992px)': {
      width: 'auto',
      // minWidth: '150px',
    }
  },
  adornment: {
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  mergeButton: {
    borderRadius: '0px 4px 4px 0px',
  }
}))
