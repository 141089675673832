import { grey } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/styles"
import { positions } from "@mui/system"
export default makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      '@media only screen and (max-width: 600px)': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(6),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }
    },
    title:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '32px',
        lineHeight: '48px',
        /* identical to box height */
        color: '#333333',
    },
    titleInCard:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '30px',
        /* identical to box height */
        color: '#000000',
    },
    sumPlan1:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '27px',
        /* identical to box height */
        color: '#009348',
    },
    sumPlan2:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        /* identical to box height */
        color: '#009348',
    },
    sumPrice:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '27px',
        /* identical to box height */
        color: '#000000',
    },
    cardEmail:{
        background: '#FFFFFF',
        border: '1px solid #009348',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        padding:'30px 25px',
        width: '100%'
    },
    editBtn:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#000000',
        textDecoration:'underline'
    },
    emailText:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '30px',
        color: '#009348',
        paddingLeft:'20px'
    },
    emailText2:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '30px',
        color: '#009348',
        paddingLeft:'20px'
    },
    errorText:{
        color: 'red',
        paddingLeft:'5px'
    },
    inputField:{
        border:'1px solid #dbdbdb',
        padding: '12px 10px',
        borderRadius:'3px',
        width:'100%',
        fontSize:'18px',
        color: '#424770',
        '@media only screen and (max-width: 600px)': {
            fontSize:'16px',
        }
    },
    lineOne:{
        borderTop: '1px dashed #dbdbdb'
    },
    lineTwo:{
        borderTop: '1px solid #dbdbdb'
    },
    poweredby:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#009348',
    },
    paymentDesc:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '19px',
        color: '#333333',
        zIndex: '5',
        position:'absolute',
        bottom:'30px',
        paddingRight: '0px',
        '@media only screen and (max-width: 600px)': {
            paddingRight: '15px',
          }
    },
    mpadding:{
        paddingBottom:'2px!important'
    },
    cardSummary:{
        background: '#FFFFFF',
        border: '1px solid #009348',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        padding:'30px 25px'
    },
    home_btn:{
        background: '#009348',
        boxShadow: '0px 0px 4px',
        border: '1px solid #009348',
        borderRadius: '4px',
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontSize: '18px',
        lineHeight: '27px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#FFFFFF',
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingTop: '7px',
        paddingBottom: '5px',
        width:'fit-content',
        '&:hover': {
          background:'white',
          color:'#009348',
        },
        '@media only screen and (max-width: 600px)': {
            width:'100%',
        }
    },
    home_btn_disable:{
        backgroundColor: 'lightgrey!important',
        borderColor: 'white!important'
    },
    subDivider: {
        borderTop: '1px solid lightgrey'
      },
      termPolicy:{
        textDecoration:'underline',
        cursor:'pointer',
        color:'#333333'
      }
}))