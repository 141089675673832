//  const STRIPE_PUBLIC_KEY = `pk_test_51MMuopDr5wWFk0xqoXihfu4tzEZHeKuPearn7AhfXkF48i0LAsbp
//  lfmy9jdK0NqRKuZEClTjqEjvD9bWHL1xyLMW00fujzHMh4` // test key

const STRIPE_PUBLIC_KEY = `pk_live_51MMuopDr5wWFk0xqFbRwWTKZCMD3zN6mr6nZzq3VpR9EV3DmMw3y9jc4J
PxRY9cGBODPuJwfz5iJOpMX3h4avlHy008oPccoOi`

const GMTID = `G-XJJ1M92KYT`

const SERVER_BASE_URL = 'https://wickpredict.io/api/v1/'
// const SERVER_BASE_URL = 'http://localhost:5000/api/v1/'

export {
  STRIPE_PUBLIC_KEY,
  GMTID,
  SERVER_BASE_URL,
}