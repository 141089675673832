import React, { useState } from 'react'
import {
    Container,
    Button,
    CircularProgress,
    LinearProgress
} from '@material-ui/core'
import useStyles from './styles'
import * as cx from 'classnames'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { isSubscribeLoadingSelector } from 'redux/modules/global/selectors'
import { createStructuredSelector } from 'reselect'
import { setIsSubscribeLoading } from 'redux/modules/global/actions'
import { refresh } from 'redux/modules/auth/actions'
import { exchangePassword } from 'redux/modules/auth/actions'
import { AiOutlineEye } from 'react-icons/ai'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import { CustomAlert } from 'components/CustomAlert'

const ChangePassword = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const [isWaiting, setIsWaiting] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertText, setAlertText] = useState('')
    const [alertMode, setAlertMode] = useState('error')
    const [showPass, setShowPass] = useState(false)

    const refreshUser = async () => {
        props.setIsSubscribeLoading({ isLoading: true })
        await props.refresh({
            success: ({ data }) => {
                props.setIsSubscribeLoading({ isLoading: false })
            },
        })
    }

    const [input, setInput] = useState({
        currentPassword:'',
        password: '',
        confirmPassword: ''
    })

    const [error, setError] = useState({
        currentPassword:'',
        password: '',
        confirmPassword: ''
    })

    const onInputChange = e => {
        const { name, value } = e.target
        setInput(prev => ({
            ...prev,
            [name]: value
        }))
        validateInput(e)
    }

    const validateInput = e => {
        let { name, value } = e.target

        console.log("inputed value==0099:",name,value)

        setError(prev => {
            const stateObj = { ...prev, [name]: "" }

            switch (name) {
                case "currentPassword":
                    if(!value){
                        stateObj[name] = "Please enter current password."
                    }
                    break
                case "password":
                    if (!value) {
                        // stateObj[name] = "Please enter Password."
                    } else if (value.length < 8) {
                        stateObj[name] = "Password lenght needs to be over 8"
                    } else if (input.confirmPassword && value !== input.confirmPassword) {
                        stateObj["confirmPassword"] = "Password and Confirm Password does not match."
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword
                    }
                    break

                case "confirmPassword":
                    if (!value) {
                        if(input.password!=''){
                            stateObj[name] = "Please enter Confirm Password."
                        }
                    } else if (input.password && value !== input.password) {
                        stateObj[name] = "Password and Confirm Password does not match."
                    }
                    break

                default:
                    break
            }

            return stateObj
        })
    }

    const handleSavePassword = async (event) => {
        event.preventDefault()
        console.log("input.password==0:", input.password)
        if(input.currentPassword.length<8){
            setAlertOpen(true)
            setAlertText("Please input current password")
            setAlertMode('error')
        }else if(input.password.length > 8 && input.password == input.confirmPassword) {
            setIsWaiting(true)
              await props.exchangePassword({
                body: { 
                    email: props.currentUser.email,
                    currentPassword:input.currentPassword,
                    newPassword: input.password },
                success: ({ data }) => {
                  console.log("set password success:", data)
                  setIsWaiting(false)
                  setAlertOpen(true)
                  setAlertText('Changed Password Successfully!')
                  setAlertMode('success')
                },
                fail: (err) => {
                  console.log("set passworderr:", err)
                  console.log("set passworderr.message:", err.message)
                  setAlertOpen(true)
                  setIsWaiting(false)
                  if(err.message==undefined){
                    setAlertText("Please re-enter your current password and try again. Current password is invalid.")
                  }else{
                    setAlertText(err.message)
                  }
                  setAlertMode('error')
                }
              })
        } else {
            setAlertOpen(true)
            setAlertText("New password needs to include 5 characters at least")
            setAlertMode('error')
        }
    }

    return (
        <>
            <div>
                <div className={cx(classes.title, 'pb-10')}>Change Password</div>
                {props.isSubscribeLoading ? <div className='linear-height'>
                    <LinearProgress />
                    <div className={classes.loading}></div>
                </div> : <div className='linear-height-border'>
                    </div>}
                <div className='pb-10'></div>
                <div className={classes.root}>
                    <Container>
                        <form className="password-form" onSubmit={handleSavePassword}>
                            <div className='position-relative'>
                                <input
                                    type={showPass ? 'text' : "password"}
                                    name="currentPassword"
                                    placeholder='Enter Old Password'
                                    value={input.currentPassword}
                                    onChange={onInputChange}
                                    onBlur={validateInput}></input>
                            </div>
                            {error.currentPassword && <div className='errText'>{error.currentPassword}</div>}
                            <div className='pb-10'></div>
                            <hr className={classes.subDivider}></hr>
                            <div className='pb-10'></div>
                            <div className='position-relative'>
                                <input
                                    type={showPass ? 'text' : "password"}
                                    name="password"
                                    placeholder='Enter Password'
                                    value={input.password}
                                    onChange={onInputChange}
                                    onBlur={validateInput}></input>
                                <div className={classes.posAlert}
                                    onClick={() => { setShowPass(!showPass) }}>
                                    {showPass ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</div>
                            </div>
                            {error.password && <div className='errText'>{error.password}</div>}
                            <div className='pb-10'></div>
                            <div>
                                <input
                                    type={showPass ? 'text' : "password"}
                                    name="confirmPassword"
                                    placeholder='Enter Confirm Password'
                                    value={input.confirmPassword}
                                    onChange={onInputChange}
                                    onBlur={validateInput}></input>
                            </div>
                            {error.confirmPassword && <div className='errText'>{error.confirmPassword}</div>}
                            <div className='pb-20'></div>
                            <div className={classes.wrapper}>
                                {!isWaiting && <Button type="submit" className={cx(classes.home_btn)}>
                                    Save new password</Button>}
                                {isWaiting && <Button className={cx(classes.home_btn)} disabled>
                                    <CircularProgress size={24} className={classes.buttonProgress} /></Button>}
                            </div>
                        </form>
                    </Container>
                    <div className={classes.label3}></div>
                </div>
                <CustomAlert
                    isOpen={alertOpen}
                    type={alertMode}
                    text={alertText}
                    onClose={() => setAlertOpen(false)}
                />
            </div>
        </>
    )
}

ChangePassword.propTypes = {
    currentUser: PropTypes.any,
    setIsSubscribeLoading: PropTypes.func,
    refresh: PropTypes.func,
    exchangePassword: PropTypes.func,
    isSubscribeLoading: PropTypes.any,
}

const actions = {
    setIsSubscribeLoading,
    refresh,
    exchangePassword
}

const selector = createStructuredSelector({
    isSubscribeLoading: isSubscribeLoadingSelector,
    currentUser: currentUserSelector,
})

export default compose(connect(selector, actions))(ChangePassword)