import useStyles from './styles'
import React, { useState, useEffect } from 'react'
import { Mobile, Default } from 'containers/ResponseLayout'
import { Header, MobileHeader } from 'containers/Header'
import { Footer, MobileFooter } from 'containers/Footer'
import { BackgroundLine } from 'components/BackgroundLine'
import { Container, Grid } from '@material-ui/core'
import 'react-modal-video/scss/modal-video.scss'
import { useMediaQuery } from 'react-responsive'
const Disclaimer = () => {
     const classes = useStyles()
     const [isLandingHeader, setIsLandingHeader] = useState(true)
     const isMobile = useMediaQuery({ maxWidth: 600 })
     useEffect(() => {
          window.addEventListener('scroll', handleScroll)
     })

     const handleScroll = () => {
          const len = window.location.search.length
          const value = window.scrollY > 50 ? false : true
          len === 0 && setIsLandingHeader(value)
     }

     const webContent = () => {
          return <div className='home-pattern-section'>
               <Container className='term-policy'>
                    <div>
                         <div className={classes.title0}>Disclaimer</div>
                         <div>The information and content provided on WickPredict
                              are for educational and informational purposes only.
                              WickPredict is not a financial advisor or a broker-dealer,
                              and does not provide investment advice or recommendations
                              regarding the purchase or sale of any securities or
                              financial instruments.</div>
                         <div>WickPredict does not guarantee the accuracy, completeness,
                               timeliness, reliability, suitability, or 
                               availability of any information or content 
                               on the website. WickPredict shall not be 
                               liable for any loss or damage, including 
                               without limitation, direct, indirect, incidental, 
                               consequential, or punitive damages, arising from 
                               or related to the use or reliance on any 
                               information or content provided on the website.</div>
                         <div>WickPredict may provide links to third-party 
                              websites or services, which are not under our 
                              control. We do not endorse or assume any 
                              responsibility for the content, accuracy, 
                              legality, or function of these third-party 
                              websites or services.</div>
                         <div>The use of any technical analysis tool, 
                              including pattern recognition, does not guarantee 
                              successful trading or investment results. The use 
                              of any tool or strategy is at the sole discretion 
                              and risk of the user.</div>
                         <div>The information and content on WickPredict may be 
                              based on data or information from third-party 
                              sources, which we believe to be reliable but do 
                              not guarantee its accuracy, completeness, or timeliness.</div>
                         <div>WickPredict is not affiliated with TradingView, 
                              MetaStock, TrendSpider, StockCharts, Recognia, 
                              Optuma, or Autochartist. The reference to these 
                              companies does not imply an endorsement or sponsorship 
                              of WickPredict.</div>
                         <div>By using WickPredict, you agree to indemnify and 
                              hold harmless WickPredict, its officers, directors, 
                              employees, and agents from any and all claims, 
                              damages, expenses, or losses arising from or related 
                              to your use of the website.</div>
                         <div>Please seek the advice of a financial professional 
                              before making any investment or trading decisions.</div>
                    </div>
             
               </Container>
          </div>
     }

     return <>
          <Mobile>
               <BackgroundLine>
                    <MobileHeader isLandingPage={isLandingHeader} />
                    {
                         webContent()
                    }
                    <MobileFooter />
               </BackgroundLine>
          </Mobile>
          <Default>
               <BackgroundLine>
                    <Header isLandingPage={isLandingHeader} />
                    {
                         webContent()
                    }
                    <Footer />
               </BackgroundLine>
          </Default>

     </>
}
export default Disclaimer