import React, { useEffect, useState, useRef } from 'react'
import useStyles from './styles'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { Button } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'

const status = [true, true, true, true]
let timerId = null
let lottieIndex = 0

const DetailDescription = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const [playStatus, setPlayStatus] = useState(status)
  const isMounted = useRef(null)

  useEffect(() => {
    isMounted.current = true
    return () => {
      timerId && clearInterval(timerId)
      timerId = null
      lottieIndex = 0

      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    document.getElementById('detail-description').addEventListener('transitionend', () => {
      if (!timerId) { timerId = setInterval(lottieTime, 2000) }
    })
    const lottieTime = () => {
      lottieIndex > 3 && clearInterval(timerId)
      const tmpStatus = status.slice()
      tmpStatus[lottieIndex] = false
      setPlayStatus(tmpStatus)
      lottieIndex++
    }
  })

  const handleMoveToStart = () => {
    props.moveToStart()
  }

  return (
    <div>
      {!isMobile && <h1 className={classes.title0}>
        Detect & Learn
      </h1>}
      <div className={classes.detailDetect}>A powerful pattern recognition tool for quickly 
        finding chart patterns on any candlestick chart!&nbsp;<span className={classes.title1}>Wick Predict </span>
        is a must have for every trader.</div>

      <Button className={classes.home_btn} onClick={()=>handleMoveToStart()}>
        {"7-DAY FREE TRIAL"}&nbsp;<HiOutlineArrowRight /></Button>
    </div>
  )
}

export default DetailDescription
