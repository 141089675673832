import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    '@media only screen and (max-width: 600px)': {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    }
  },
  title: {
    color: '#333333',
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent:'center',
    '@media only screen and (max-width: 600px)': {
      fontSize: '26px',
      lineHeight: '36px',
      fontSize: '24px',
      lineHeight: '36px',
      textDecorationLine: 'none',
    }
  },
  descTitle:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#333333',
  },
  description:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#333333',
    textAlign:'center',
    '@media only screen and (max-width: 600px)': {
      fontSize: '26px',
      lineHeight: '36px',
      fontSize: '16px',
      lineHeight: '24px',
    }
  }
}))
