export const DO_LOGIN = 'DO_LOGIN'
export const DO_SIGNUP = 'DO_SIGNUP'
export const DO_SIGNUP_COUPON = 'DO_SIGNUP_COUPON'
export const DO_SIGNUP_TRIAL = 'DO_SIGNUP_TRIAL'
export const DO_REFRESH = 'DO_REFRESH'
export const DO_REFRESH_TOKEN = 'DO_REFRESH_TOKEN'
export const DO_GOOGLE_LOGIN = 'DO_GOOGLE_LOGIN'
export const DO_GOOGLE_SIGNUP = 'DO_GOOGLE_SIGNUP'
export const DO_FACEBOOK_LOGIN = 'DO_FACEBOOK_LOGIN'
export const DO_FACEBOOK_SIGNUP = 'DO_FACEBOOK_SIGNUP'
export const DO_LOGOUT = 'DO_LOGOUT'
export const IS_UNAUTHORIZED = 'IS_UNAUTHORIZED'
export const CHECK_EMAIL_VALIDATION = 'CHECK_EMAIL_VALIDATION'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const DELETE_AUTH_USER = 'DELETE_AUTH_USER'
export const REQUEST_ACCOUNT_VERIFY = 'REQUEST_ACCOUNT_VERIFY'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const CHECK_ACCOUNT_VERIFICATION = 'CHECK_ACCOUNT_VERIFICATION'
export const GET_ACTIVATIONCODE = 'GET_ACTIVATIONCODE'
export const EXCHANGE_PASSWORD = 'EXCHANGE_PASSWORD'
export const GET_USERS = 'GET_USERS'