import React, { useState, useEffect } from 'react'
import {
  Button,
  Input,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import PropTypes from 'prop-types'
import useStyles from './MobileSearchBar.style'
import search from 'assets/images/Icon/search.svg'
import { useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { setSearchMode } from 'redux/modules/global/actions'
import { searchModeSelector } from 'redux/modules/global/selectors'

const MobileSearchBar = ({
  isLandingPage,
  placeholder,
  initialValue,
  setSearchMode,
  searchMode,
  onSearch,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [searchValue, setSearchValue] = useState('')
  const [searchModeString, setSearchModeString] = useState('')

  useEffect(() => {
    initialValue && setSearchValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    setSearchModeString(searchMode)
    setSearchValue('')
  }, [searchMode])

  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }
  const handleSearch = () => {
    onSearch && onSearch()
    history.push(`/search?mode=${searchModeString}${searchValue !== '' ? `&search=${searchValue}` : ''}`)
  }
  const handleRemove = () => {
    setSearchValue('')
    !isLandingPage && history.push(`/search?mode=${searchModeString}`)
  }

  return (
    <div className={isLandingPage ? classes.root : classes.normalRoot}>
      <Input
        className={classes.textField}
        disableUnderline
        placeholder={placeholder ? placeholder : 'Try “sports” for example'}
        startAdornment={
          <InputAdornment position='start' className={classes.adornment}>
            <img src={search} alt='search' />
          </InputAdornment>
        }
        endAdornment={
          searchValue &&
          <InputAdornment position='end' className={classes.adornment}>
            <IconButton
              onClick={handleRemove}
              size='small'
            >
              <CancelRoundedIcon fontSize='inherit' />
            </IconButton>
          </InputAdornment>
        }
        value={searchValue}
        onChange={handleChange}
      />
      <Button className={classes.searchButton} onClick={handleSearch}>Search</Button>
    </div>
  )
}

MobileSearchBar.propTypes = {
  isLandingPage: PropTypes.bool,
  placeholder: PropTypes.string,
  setSearchMode: PropTypes.func,
  searchMode: PropTypes.string,
}

const actions = {
  setSearchMode,
}

const selector = createStructuredSelector({
  searchMode: searchModeSelector,
})

export default compose(connect(selector, actions))(MobileSearchBar)
