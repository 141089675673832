import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(10),
    '@media only screen and (max-width: 600px)': {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(5),
    },
    paddingLeft: theme.spacing(6),
    '@media only screen and (max-width: 600px)': {
      paddingLeft: theme.spacing(3),
    },
  },
  title: {
    color: '#333333',
    marginBottom: theme.spacing(3),
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '40px',
    lineHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent:'center',
    paddingBottom: '30px',
    '@media only screen and (max-width: 600px)': {
      fontWeight: 600,
      fontSize: '26px',
      lineHeight: '39px',
      paddingBottom: '15px',
    }
  },

  descTitle:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#333333',
  },
  description:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#333333',
  },

  home_btn:{
    background: '#009348',
    boxShadow: '0px 0px 4px',
    border: '1px solid #009348',
    borderRadius: '4px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    // font-weight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '7px',
    paddingBottom: '5px',
    '&:hover': {
      background:'white',
      color:'#009348',
    },
  },
  videoCover:{
    width:'100%',
    height:'320px',
    position: 'relative',
    '@media only screen and (max-width: 1200px)': {
      height:'250px',
    },
  },
  logoVideo:{
    padding:'20px 0px',
  },
  managerAvatar:{
    borderRadius:'100px',
    height:'120px'
  },
  managerCard:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 0px 50px',
    gap: '25px',
    width: '330px',
    height: '377px',
    background: '#FBF7EE',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    marginLeft:'40px',
    marginRight:'40px',
    marginTop:'10px',
    marginBottom:'10px',
    position:'relative',
  },
  priceTop:{
    position:'absolute',
    top:'-6px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  managerName:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '36px',
    lineHeight: '38px',
    /* identical to box height */
    textAlign: 'center',
    /* Main color */
    color: '#009348',
    '@media only screen and (max-width: 600px)': {
      fontSize: '26px',
      lineHeight: '39px',
    }
  },
  managerSubPrice:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    /* identical to box height */
    textAlign: 'center',
    /* Main color */
    color: '#333333',
    paddingTop:'10px'
  },
  managerPrice:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '36px',
    lineHeight: '34px',
    /* identical to box height */
    textAlign: 'center',
    /* Main color */
    color: '#333333',
    '@media only screen and (max-width: 600px)': {
      fontSize: '26px',
      lineHeight: '39px',
    }
  },
  managerDetail:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '22px',
    textAlign: 'center',
    /* Dark */
    color: '#333333',
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '21px',
    }
  },
  logoImg:{
    width:'inherit',
    '@media only screen and (max-width: 1200px)': {
      width:'250px'
    },
  },
}))
