import React from 'react'
import {
  Container,
  Grid,
  IconButton,
  Button
} from '@material-ui/core'
import useStyles from './MobileFooter.styles'
import white_logo from 'assets/images/images/logo_white.svg'
import * as cx from 'classnames'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import footer_lock from 'assets/images/images/lock.svg'
import footer_stripe from 'assets/images/images/stripe.svg'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import icon_facebook from 'assets/images/images/icon_facebook.svg'
import icon_twitter from 'assets/images/images/icon_twitter.svg'
import icon_linkedin from 'assets/images/images/icon_linkedin.svg'

const MobileFooter = (
  props
) => {
  const history = useHistory()
  const classes = useStyles()

  const handleMoveToStart = () => {
    props.moveToStart()
  }

  return (
    <div className={classes.root}>
      <div className='pb-20'><Link to='/'><img src={white_logo} alt='logo' className={classes.image} /></Link></div>
      <div className='pb-20'>
        <div className={classes.bold} onClick={()=>history.push('/about')}>About</div>
        <div className={classes.bold} onClick={()=>history.push('/#feature')}>Feature</div>
        <div className={classes.bold} onClick={()=>history.push('/#pricing')}>Pricing</div>
      </div>
      <div className='pb-20'>
        <div className={classes.bold} onClick={()=>history.push('/terms')}>Terms & Conditions</div>
        <div className={classes.bold} onClick={()=>history.push('/policy')}>Privacy Policy</div>
        <div className={classes.bold} onClick={()=>history.push('/disclaimer')}>Disclaimer</div>
      </div>
      <div>
        <Button className={cx('center-center', classes.home_btn)}
        onClick={()=>handleMoveToStart()}
        >{"7-Day Free Trial"} &nbsp;
          <HiOutlineArrowRight /></Button>
        <div className="center-start pb-10">
          <IconButton>
            <img src={icon_twitter} alt="" />
          </IconButton>
          <IconButton>
            <img src={icon_facebook} alt="" />
          </IconButton>
          <IconButton>
            <img src={icon_linkedin} alt="" />
          </IconButton>
        </div>
      </div>
      <Grid container spacing={0} className={classes.footerBottom}>
        <Grid item xs={12}>
          <div className={cx(classes.centerStartColumn,'pb-10')}>
            <div className='pb-10'>Support@wickpredict.io</div>
          </div>
        </Grid>
        <Grid item xs={12} className="center-start pb-30">© 2023. All Rights Reserved by WickPredict</Grid>
      </Grid>
    </div>
  )
}

export default MobileFooter
