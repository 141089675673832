import { takeEvery, takeLatest } from 'redux-saga/effects'
import * as CONSTANTS from 'redux/modules/subscribe/constants'
import apiCall from '../api/apiCall'
import { get, pick } from 'lodash'

const doCreateStripeCustomer = apiCall({
  type: CONSTANTS.CREATE_STRIPE_CUSTOMER,
  method: 'post',
  path: 'stripe/create-customer',
})

const doCreateStripeSubscription = apiCall({
  type: CONSTANTS.CREATE_STRIPE_SUBSCRIPTION,
  method: 'post',
  path: 'stripe/create-subscription',
})

const getSubscriptionInfo = apiCall({
  type: CONSTANTS.GET_SUBSCRIPTION_INFO,
  method: 'post',
  path: 'stripe/get-activation-code',
})
const checkCouponValidation = apiCall({
  type: CONSTANTS.CHECK_COUPON_VALIDATION,
  method: 'post',
  path: 'stripe/check-coupon-code',
})

const getSubscribeDetail = apiCall({
  type: CONSTANTS.GET_SUBSCRIBE_DETAIL,
  method: 'post',
  path: 'stripe/get-subscribe-detail',
})

const getInvoices = apiCall({
  type: CONSTANTS.GET_INVOICES,
  method: 'post',
  path: 'stripe/get-invoices',
})

const getAdminInvoices = apiCall({
  type: CONSTANTS.GET_ADMIN_INVOICES,
  method: 'post',
  path: 'stripe/get-admin-invoices',
})

const doUpdateStripeSubscription = apiCall({
  type: CONSTANTS.UPDATE_STRIPE_SUBSCRIPTION,
  method: 'post',
  path: 'stripe/update-subscription',
})

const cardUpdateInit = apiCall({
  type: CONSTANTS.CARD_UPDATE_INIT,
  method: 'post',
  path: 'stripe/card-update-init',
})

const cardUpdateFinal = apiCall({
  type: CONSTANTS.CARD_UPDATE_FINAL,
  method: 'post',
  path: 'stripe/card-update-final',
})

const doGetStripeSubscriptions = apiCall({
  type: CONSTANTS.GET_ALL_STRIPE_SUBSCRIPTIONS,
  method: 'get',
  path: () => `/stripe/`,
  payloadOnSuccess: (res, { payload }) => ({
    ...res,
    ...pick(get(payload, 'params', {}), ['page', 'totalPages']),
  })
})

const doCancelStripeSubscription = apiCall({
  type: CONSTANTS.CANCEL_STRIPE_SUBSCRIPTION,
  method: 'post',
  path: 'stripe/cancel-subscription',
})

const doCreatePaypalSubscription = apiCall({
  type: CONSTANTS.CREATE_PAYPAL_SUBSCRIPTION,
  method: 'post',
  path: 'paypal/create-subscription',
})

const doUpdatePaypalSubscription = apiCall({
  type: CONSTANTS.UPDATE_PAYPAL_SUBSCRIPTION,
  method: 'post',
  path: 'paypal/update-subscription',
})

const doUGetPaypalSubscriptions = apiCall({
  type: CONSTANTS.GET_ALL_PAYPAL_SUBSCRIPTIONS,
  method: 'get',
  path: () => `/paypal/`,
  payloadOnSuccess: (res, { payload }) => ({
    ...res,
    ...pick(get(payload, 'params', {}), ['page', 'totalPages']),
  })
})

const doCancelPaypalSubscription = apiCall({
  type: CONSTANTS.CANCEL_PAYPAL_SUBSCRIPTION,
  method: 'post',
  path: 'paypal/cancel-subscription',
})

export default function* rootSaga() {
  yield takeLatest(CONSTANTS.CREATE_STRIPE_CUSTOMER, doCreateStripeCustomer)
  yield takeLatest(CONSTANTS.CREATE_STRIPE_SUBSCRIPTION, doCreateStripeSubscription)
  yield takeLatest(CONSTANTS.UPDATE_STRIPE_SUBSCRIPTION, doUpdateStripeSubscription)
  yield takeEvery(CONSTANTS.GET_ALL_STRIPE_SUBSCRIPTIONS, doGetStripeSubscriptions)
  yield takeLatest(CONSTANTS.CANCEL_STRIPE_SUBSCRIPTION, doCancelStripeSubscription)
  yield takeLatest(CONSTANTS.GET_SUBSCRIPTION_INFO, getSubscriptionInfo)
  yield takeLatest(CONSTANTS.CHECK_COUPON_VALIDATION, checkCouponValidation)
  yield takeLatest(CONSTANTS.GET_SUBSCRIBE_DETAIL, getSubscribeDetail)
  yield takeLatest(CONSTANTS.CARD_UPDATE_INIT, cardUpdateInit)
  yield takeLatest(CONSTANTS.CARD_UPDATE_FINAL, cardUpdateFinal)
  yield takeLatest(CONSTANTS.GET_INVOICES, getInvoices)
  yield takeLatest(CONSTANTS.GET_ADMIN_INVOICES, getAdminInvoices)
  
  yield takeLatest(CONSTANTS.CREATE_PAYPAL_SUBSCRIPTION, doCreatePaypalSubscription)
  yield takeLatest(CONSTANTS.UPDATE_PAYPAL_SUBSCRIPTION, doUpdatePaypalSubscription)
  yield takeEvery(CONSTANTS.GET_ALL_PAYPAL_SUBSCRIPTIONS, doUGetPaypalSubscriptions)
  yield takeLatest(CONSTANTS.CANCEL_PAYPAL_SUBSCRIPTION, doCancelPaypalSubscription)
}
