import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
  },
  logo: {
    userSelect: 'none',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '157px',
  },
  linkText: {
    color: '#333333',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    fontFamily: 'Roboto',
  },
  linkList: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  linkItem: {
    height: '36px',
    '&:hover': {
      borderRadius: '4px',
      background: '#DDDDDD',
    }
  },
  siteButton: {
    height: '46px',
    fontSize: '16px',
    lineHeight: '19px',
  },
  logoutBtn: {
    marginTop: theme.spacing(3),
  },
  home_btn: {
    background: '#009348',
    boxShadow: '0px 0px 4px',
    border: '1px solid #009348',
    borderRadius: '4px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    // font-weight: '600',
    fontSize: '15px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '7px',
    paddingBottom: '5px',
    '&:hover': {
      background: 'white',
      color: '#009348',
    },
  },

  navItem_btn: {
    width: '100%',
    justifyContent: 'start',
  },
  navitem: {
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    /* identical to box height */
    color: '#333333',
    display: 'flex',
    alignItem: 'center',
    border: 'none',

    '&:hover': {
      // background:
      //   'linear-gradient(#fff,#fff) padding-box, linear-gradient(165deg, #0052FF 0%, #FF00D0 100%) border-box',
    },
  },
  navitem_active: {
    color: '#009348',
  },
  navItem_out: {
    marginTop: '100px'
  }
}))
