import React, { lazy, Suspense } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import Loading from 'components/Loading'
import { APP_PREFIX_PATH } from 'helpers/AppConfig'
import Dashboard from './dashboard'
import Subscription from './subscriptionMy'
import SubscriptionPlans from "./subscriptionPlans"
import Invoices from "./invoices"
import Users from "./users"
import Admins from "./admins"
import Transactions from "./transactions"
import ChangePassword from "./changePassword"
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { createStructuredSelector } from 'reselect'

export const AppViews = (
  {currentUser}
) => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboard`} component={Dashboard} />
        <Route path={`${APP_PREFIX_PATH}/subscription`} component={Subscription} />
        <Route path={`${APP_PREFIX_PATH}/subscriptionplans`} component={SubscriptionPlans} />
        <Route path={`${APP_PREFIX_PATH}/invoices`} component={Invoices} />
        <Route path={`${APP_PREFIX_PATH}/changepassword`} component={ChangePassword} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={Users} />
        <Route path={`${APP_PREFIX_PATH}/admins`} component={Admins} />
        <Route path={`${APP_PREFIX_PATH}/transactions`} component={Transactions} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={currentUser!=null?currentUser.level=="1"
        ?`${APP_PREFIX_PATH}/users`
        :`${APP_PREFIX_PATH}/dashboard`:`${APP_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  )
}

const selector = createStructuredSelector({
  currentUser: currentUserSelector,
})

export default compose(connect(selector, null))(AppViews)
