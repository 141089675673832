import useStyles from './styles'
import React, { useState, useEffect } from 'react'
import { Mobile, Default } from 'containers/ResponseLayout'
import { Header, MobileHeader } from 'containers/Header'
import { Footer, MobileFooter } from 'containers/Footer'
import { BackgroundLine } from 'components/BackgroundLine'
import { Container, Grid } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
const Policy = () => {
     const classes = useStyles()
     const [isLandingHeader, setIsLandingHeader] = useState(true)
     const isMobile = useMediaQuery({ maxWidth: 600 })
     useEffect(() => {
          window.addEventListener('scroll', handleScroll)
     })

     const handleScroll = () => {
          const len = window.location.search.length
          const value = window.scrollY > 50 ? false : true
          len === 0 && setIsLandingHeader(value)
     }

     const webContent = () => {
          return <div className='home-pattern-section'>
               <Container className='term-policy'><BackgroundLine>
                    <div>
                         <div className={classes.title0}>Privacy Policy</div>
                         <div>Wick Predict LLC Privacy Policy</div>
                         <div>This Privacy Policy describes how your personal information is
                              collected, used, and shared when you visit or make a purchase
                              from algopro.us (the “Site”).</div>
                         <div>When you visit the Site, we automatically collect certain information
                              about your device, including information about your web browser,
                              IP address, time zone, and some of the cookies that are installed
                              on your device. Additionally, as you browse the Site, we collect
                              information about the individual web pages or products that you
                              view, what websites or search terms referred you to the Site,
                              and information about how you interact with the Site. We refer
                              to this automatically collected information as “Device Information.”</div>
                         <div>We collect Device Information using the following technologies:</div>
                         <div>    - “Cookies” are data files that are placed on your device or
                              computer and often include an anonymous unique identifier. For more
                              information about cookies, and how to disable cookies, visit
                              http://www.allaboutcookies.org.</div>
                         <div>    - “Log files” track actions occurring on the Site, and collect
                              data including your IP address, browser type, Internet service provider,
                              referring/exit pages, and date/time stamps.</div>
                         <div>    - “Web beacons,” “tags,” and “pixels” are electronic files used
                              to record information about how you browse the Site.</div>
                         <div>Additionally, when you make a purchase or attempt to make a purchase
                              through the Site, we collect certain information from you, including
                              your name, billing address, shipping address, payment information including credit
                              card numbers.</div>
                         <div>When we talk about “Personal Information” in this Privacy Policy, we
                              are talking both about Device Information and Order Information.</div>
                         <div>We use the Order Information that we collect generally to fulfill any
                              orders placed through the Site (including processing your payment information,
                              arranging for shipping, and providing you with invoices and/or order confirmations).
                              Additionally, we use this Order Information to:</div>
                         <div>Communicate with you.</div>
                         <div>Screen our orders for potential risk or fraud; and</div>
                         <div>When in line with the preferences you have shared with us, provide you with
                              information or advertising relating to our products or services.</div>
                         <div>We use the Device Information that we collect to help us screen for potential
                              risk and fraud (in particular, your IP address), and more generally to improve
                              and optimize our Site (for example, by generating analytics about how our customers
                              browse and interact with the Site, and to assess the success of our marketing and
                              advertising campaigns).</div>
                         <div>We share your Personal Information with third parties to help us use your Personal
                              Information, as described above. We also use Google Analytics to help us understand
                              how our customers use the Site--you can read more about how Google uses your Personal
                              Information here:  https://www.google.com/intl/en/policies/privacy/.  You can also
                              opt-out of Google Analytics here:  https://tools.google.com/dlpage/gaoptout.</div>
                         <div>Finally, we may also share your Personal Information to comply with applicable laws
                              and regulations, to respond to a subpoena, search warrant or other lawful request for
                              information we receive, or to otherwise protect our rights.</div>
                         <div>As described above, we use your Personal Information to provide you with targeted
                              advertisements or marketing communications we believe may be of interest to you.
                              For more information about how targeted advertising works, you can visit the Network
                              Advertising Initiative’s (“NAI”) educational page at
                              http://www.networkadvertising.org/understanding-online
                              -advertising/how-does-it-work.</div>
                         <div>Additionally, you can opt out of some of these services by visiting the Digital
                              Advertising Alliance’s opt-out portal at:  http://optout.aboutads.info/.</div>
                         <div>Please note that we do not alter our Site’s data collection and use practices when
                              we see a Do Not Track signal from your browser.</div>
                         <div>If you are a European resident, you have the right to access personal information
                              we hold about you and to ask that your personal information be corrected, updated,
                              or deleted. If you would like to exercise this right, please contact us through the
                              contact information below.</div>
                         <div>Additionally, if you are a European resident, we note that we are processing your
                              information in order to fulfill contracts we might have with you (for example if
                              you make an order through the Site), or otherwise to pursue our legitimate business
                              interests listed above.  Additionally, please note that your information will be
                              transferred outside of Europe, including to Canada and the United States.</div>
                         <div>When you place an order through the Site, we will maintain your Order Information for
                              our records unless and until you ask us to delete this information.</div>
                         <div>We may update this privacy policy from time to time in order to reflect, for example,
                              changes to our practices or for other operational, legal or regulatory reasons.</div>
                         <div>CONTACT US</div>
                         <div>For more information about our privacy practices, if you have questions, or if you
                              would like to make a complaint, please contact us by e-mail at
                              support@wickpredict.io</div>
                    </div>
               </BackgroundLine>

               </Container>
          </div>
     }

     return <>
          <Mobile>
               <BackgroundLine>
                    <MobileHeader isLandingPage={isLandingHeader} />
                    {
                         webContent()
                    }
                    <MobileFooter />
               </BackgroundLine>
          </Mobile>
          <Default>
               <BackgroundLine>
                    <Header isLandingPage={isLandingHeader} />
                    {
                         webContent()
                    }
                    <Footer />
               </BackgroundLine>
          </Default>

     </>
}
export default Policy