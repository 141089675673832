import React, { useState } from 'react'
import useStyles from './Form.styles'
import {
  Container,
  Divider,
  CircularProgress,
  Button
} from '@material-ui/core'
import { CustomInput } from 'components/CustomInput'
import { forgotPassword } from 'redux/modules/auth/actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as cx from 'classnames'
import { useHistory } from 'react-router-dom'
import { CustomAlert } from 'components/CustomAlert'

const Form = ({
  forgotPassword
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [isWaiting, setIsWaiting] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [alertMode, setAlertMode] = useState('error')

  const handleLogin = () => {
    history.push('/signin')
  }

  const handleStart =() => {
    history.push('/start')
  }

  const handleClickReset = async () => {
    setIsWaiting(true)
    await forgotPassword({
      body: { email },
      success: () => {
        setIsWaiting(false)
        setAlertOpen(true)
        setAlertText('Sent request Successfully!')
        setAlertMode('success')
      },
      fail: (err) => {
        console.log("forgot password result:",err)
        setIsWaiting(false)
        setAlertText(err.data.message)
        setAlertMode('error')
        setAlertOpen(true)
      }
    })
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.title}>Reset password</div>
        <div className='pb-30'></div>
        <CustomInput placeholder='E-Mail' onChange={value => setEmail(value)} />
        <div className='pb-5'></div>
        <div className={classes.wrapper}>
          {!isWaiting && <Button className={cx(classes.home_btn)} onClick={handleClickReset}>Reset password</Button>}
          {isWaiting && <Button className={cx(classes.home_btn)} disabled>
            <CircularProgress size={24} className={classes.buttonProgress} /></Button>}
          
        </div>
        <div className='pb-10'></div>
        <div className={cx(classes.label3,'pointer')} onClick={handleLogin}>
          Already have an account?
        </div>
        <div className={classes.label4}>
          Already a member? <span className={cx(classes.label4,'pointer')} onClick={handleStart}>Get Free Trial</span>
        </div>
        <CustomAlert isOpen={alertOpen} type={alertMode} text={alertText} onClose={() => setAlertOpen(false)} />
      </div>
    </>
  )
}

Form.propTypes = {
  forgotPassword: PropTypes.func,
}

const actions = {
  forgotPassword,
}

export default compose(connect(null, actions))(Form)
