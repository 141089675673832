import { get } from 'lodash'

export const subscribeStateSelector = (state) =>
  get(state, 'subscribe')

export const subscribeStatusSelector = (state) =>
  get(state, 'subscribe.status', null)

export const activaSelector = (state) =>
  get(state, 'subscribe.status', null)

export const subscriptionInfoSelector = (state) =>
  get(state, 'subscribe.subscriptionInfo',null)

export const last4Selector = (state) =>
  get(state, 'subscribe.last4',null)

export const subscribeDetailSelector = (state) =>
  get(state, 'subscribe.subscribeDetail',null)

export const invoicesSelector = (state) =>
  get(state, 'subscribe.invoices',null)

export const adminInvoicesSelector = (state) =>
get(state, 'subscribe.adminInvoices',null)