import React, {useState} from 'react'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { DetailDescriptionTwo } from 'components/DetailDescription'
import { Mobile, Default } from 'containers/ResponseLayout'
import { VideoPlayer } from 'components/VideoPlayer'
import logo_video from 'assets/images/images/logo_video.png'
import play_icon from 'assets/images/Icon/play.svg'
import { IconButton } from '@material-ui/core'

const HomeDetailSection = (props) => {
  const classes = useStyles()
  const mondayVideo = `https://s3.us-east-2.amazonaws.com/wickpredict.io/HighlightMovie/wickpredict_Web.mp4`
  const [isOpen, setOpen] = useState(false)

  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Mobile>
          <Grid item xs={12}>
          {!isOpen &&
            <div className={classes.videoCover}>
              <div className={classes.videoBox}>
                <div>Install & Try Now!</div>
                <div className={classes.logoVideo}><img className={classes.logoImg} src={logo_video} alt='' /></div>
                <div>&nbsp;</div>
              </div>
              <div className={classes.videoPlay} onClick={() => props.showVideo()}>
              <IconButton onClick={() =>   setOpen(true)}>
                  <img src={play_icon} alt='p' />
                </IconButton>
              </div>
            </div>
            }
             {isOpen &&  <div className={classes.videoCover}>
              <div className={classes.videoBoxMobile}>
              <video autoplay="false" name="media" controls="true" className={classes.videoPlayer2}>
                <source src="https://wickpredict.s3.amazonaws.com/Top10/Wick+Predict+Explainer.mp4" 
                type="video/mp4"/></video>
                </div>  
                </div>
            }
          </Grid>
          <Grid item xs={12}>
            <DetailDescriptionTwo />
          </Grid>
        </Mobile>

        <Default className="center-between">
          <Grid item xs={6} className="center-center">
          {!isOpen &&
            <div className={classes.videoCover}>
              <div className={classes.videoBox}>
                <div>Install & Try Now!</div>
                <div className={classes.logoVideo}><img className={classes.logoImg} src={logo_video} alt='' /></div>
                <div>&nbsp;</div>
              </div>
              <div className={classes.videoPlay} onClick={() => props.showVideo()}>
              <IconButton onClick={() =>   setOpen(true)}>
                  <img src={play_icon} alt='p' />
                </IconButton>
              </div>
            </div>
            }
             {isOpen &&  <div className={classes.videoCover}>
              <div className={classes.videoBox}>
              <video autoplay="false" name="media" controls="true" className={classes.videoPlayer2}>
                <source src="https://wickpredict.s3.amazonaws.com/Top10/Wick+Predict+Explainer.mp4" 
                type="video/mp4"/></video>
                </div>  
                </div>
            }
          </Grid>
          <Grid item xs={6}>
            <DetailDescriptionTwo />
          </Grid>
        </Default>
      </Grid>

    </Container>
  )
}

export default HomeDetailSection
