import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    display:'flex',
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    '@media only screen and (max-width: 600px)': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(3),
    }
  },
  sidenavA:{
    width:'220px'
  },
  appContent:{
    width: 'calc(100% - 220px)',
    '@media only screen and (max-width: 700px)': {
      width: '100%'
    }
  }
}))
