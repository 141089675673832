import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    width: '100%',
  },
  normalRoot: {
    width: '100%',
    marginBottom: '8px',
  },
  searchButton: {
    height: '46px',
    width: '100%',
    backgroundColor: '#0052FF',
    color: 'white',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0052FF',
    }
  },
  textField: {
    backgroundColor: 'white',
    borderRadius: '4px',
    marginBottom: theme.spacing(1.5),
    height: '46px',
    width: '100%',
    border: '1px solid #DDDDDD',
  },
  adornment: {
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  }
}))
