import * as cx from 'classnames'
import useStyles from './styles'
import React, { useState, useEffect } from 'react'
import { Mobile, Default } from 'containers/ResponseLayout'
import { Header, MobileHeader } from 'containers/Header'
import { RegisterStart } from 'containers/RegisterStart'
import { Footer, MobileFooter } from 'containers/Footer'
import { BackgroundLine } from 'components/BackgroundLine'
import { Container, Grid } from '@material-ui/core'
import 'react-modal-video/scss/modal-video.scss'
import { useMediaQuery } from 'react-responsive'
const About = () => {
     const classes = useStyles()
     const [isLandingHeader, setIsLandingHeader] = useState(true)
     const isMobile = useMediaQuery({ maxWidth: 600 })
     useEffect(() => {
          window.addEventListener('scroll', handleScroll)
     })

     const handleScroll = () => {
          const len = window.location.search.length
          const value = window.scrollY > 50 ? false : true
          len === 0 && setIsLandingHeader(value)
     }

     const webContent = () => {
          return <div className='home-pattern-section'>
               <Container className='term-policy'>
                    <div>
                         <div className={classes.title0}>About Us</div>
                         <div>Welcome to Wick Predict!</div>
                         <div>We are a team of experienced traders and
                              developers dedicated to creating innovative
                              software that helps traders gain an edge in
                              the financial markets. Our software was
                              created by Jason Kraft, a former trader
                              with over 20 years of trading experience.</div>
                         <div>After spending years analyzing market data,
                              Jason realized the need for a more efficient
                              and effective way to identify profitable trading
                              opportunities. He combined his knowledge of
                              trading with advanced algorithms and technical
                              analysis to develop WickPredict.</div>
                         <div>Our software utilizes AI-based pattern recognition
                              and educational tools such as candlestick chart
                              pattern recognition and a digital flashcard deck
                              to help traders easily detect technical analysis
                              patterns on desktop trading charts.</div>
                         <div>We understand the importance of having reliable and
                              accurate tools when it comes to trading, which is why
                              we continuously work to improve our software and provide
                              exceptional customer support.</div>
                         <div>Thank you for choosing WickPredict as your go-to
                              platform for trading analysis.</div>
                    </div>

               </Container>
          </div>
     }

     return <>
          <Mobile>
               <BackgroundLine>
                    <MobileHeader isLandingPage={isLandingHeader} />
                    {
                         webContent()
                    }
                    <MobileFooter />
               </BackgroundLine>
          </Mobile>
          <Default>
               <BackgroundLine>
                    <Header isLandingPage={isLandingHeader} />
                    {
                         webContent()
                    }
                    <Footer />
               </BackgroundLine>
          </Default>

     </>
}
export default About