import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    width: '520px',
    background: '#FFFFFF',
    borderRadius: '15px',
    padding:'20px 37px',
    marginTop: '60px',
    '@media only screen and (max-width: 600px)': {
      width: 'auto',
      borderRadius: '5px',
      marginTop: '40px',
    }
  },
  title: {
    color: '#333333',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '48px',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    '@media only screen and (max-width: 600px)': {
      fontSize: '26px',
      lineHeight: '30px',
      textAlign: 'left',
    }
  },
  description: {
    color: '#333333',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'left',
    }
  },
  divider: {
    marginBottom: '24px !important',
  },
  divider2: {
    marginTop: '12px !important',
    marginBottom: '12px !important',
  },
  home_btn:{
    width:'100%',
    height:'50px',
    background: '#009348',
    boxShadow: '0px 0px 4px',
    border: '1px solid #009348',
    borderRadius: '4px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    // font-weight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '7px',
    paddingBottom: '5px',
    '&:hover': {
      background:'white',
      color:'#009348',
    },
  },
  label1: {
    color: '#333333',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    marginBottom: theme.spacing(0.5),
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '16px',
    }
  },
  label2: {
    color: '#333333',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    '& > span': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    '@media only screen and (max-width: 600px)': {
      fontSize: '12px',
      lineHeight: '14px',
    }
  },

  label3: {
    color: '#333333',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '27px',
    textAlign: 'center',
    textDecoration:'underline',
    marginBottom: theme.spacing(2),
    '& > span': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '16px',
    }
  },
  label4: {
    color: '#333333',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '27px',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    '& > span': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '16px',
    }
  }
  
}))
