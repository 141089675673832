import { makeStyles } from "@material-ui/styles"
export default makeStyles(theme => ({
    navItem_btn:{
        width:'100%',
        justifyContent:'start',
    },
    navitem:{
        cursor:'pointer',
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '30px',
        /* identical to box height */
        color: '#333333',
        display:'flex',
        alignItem:'center',
        border:'none',
        
        '&:hover': {
            // background:
            //   'linear-gradient(#fff,#fff) padding-box, linear-gradient(165deg, #0052FF 0%, #FF00D0 100%) border-box',
          },
    },
    navitem_active:{
        color: '#009348',
    },
    navItem_out:{
        marginTop: '100px'
    }
}))