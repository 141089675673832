import { makeStyles } from "@material-ui/styles"
export default makeStyles(theme => ({
    cardSummary:{
        background: '#FFFFFF',
        /* Main color */
        border: '1px solid #009348',
        borderRadius: '10px',
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '22px',
        lineHeight: '40px',
        color: '#449342',
        padding:'35px 35px',
        '@media only screen and (max-width: 600px)': {
            background:'#009348',
          }
    },
    cardTitle:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '38px',
        lineHeight: '40px',
        color: '#009348',
        '@media only screen and (max-width: 600px)': {
            fontSize: '32px',
            lineHeight: '48px',
            color:'white',
          }
    },
    cardContent:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '22px',
        lineHeight: '30px',
        color: '#009348',
        '@media only screen and (max-width: 600px)': {
            fontSize: '18px',
            lineHeight: '30px',
            color:'white',
          }
    },
    cardDesc:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '30px',
        color: '#009348',
        '@media only screen and (max-width: 600px)': {
            fontSize: '16px',
            lineHeight: '21px',
            color:'white',
          }
    },

    cardCode:{
        background: '#FFFFFF',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        width:'100%',
        padding:'30px 25px',
        marginTop:'20px',
        
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '22px',
        lineHeight: '40px',
        color: '#449342',

    },
    codeBox:{
        background: '#EBFCEA',
        border: '1px solid #009348',
        borderRadius: '4px',
        paddingLeft:'15px',
        height:'40px',
        fontSize: '18px',
        lineHeight: '21px',
        color: '#000000',

    },
    copiedText:{
        fontSize:'15px'
    },
    home_btn: {
        background: '#009348',
        boxShadow: '0px 0px 4px',
        border: '1px solid #009348',
        borderRadius: '4px',
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        // font-weight: '600',
        fontSize: '18px',
        lineHeight: '27px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#FFFFFF',
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingTop: '7px',
        paddingBottom: '5px',
        textTransform: 'capitalize',
        '&:hover': {
          background: 'white',
          color: '#009348',
        },
      },
}))