import React from 'react'
import {
  Container,
  Grid,
  Button,
  IconButton
} from '@material-ui/core'
import useStyles from './Footer.styles'
import white_logo from 'assets/images/logo/logo.svg'
import heart_small from 'assets/images/Icon/heart_small.svg'
import * as cx from 'classnames'
import { Link, useHistory } from 'react-router-dom'
import { CustomIconButton } from 'components/CustomIconButton'
import footer_lock from 'assets/images/images/lock.svg'
import footer_stripe from 'assets/images/images/stripe.svg'
import { HiOutlineArrowRight } from 'react-icons/hi'
import icon_facebook from 'assets/images/images/icon_facebook.svg'
import icon_twitter from 'assets/images/images/icon_twitter.svg'
import icon_linkedin from 'assets/images/images/icon_linkedin.svg'
import icon_instgram from 'assets/images/images/icon_instgram.svg'

const Footer = (props) => {
  const classes = useStyles()
  const history = useHistory()

  const handleMoveToStart = () => {
    props.moveToStart()
  }

  return (
    <div className={cx(classes.root, 'pb-30')}>
      <Container>
        <div className='pb-30'><Link to='/'><img src={white_logo} alt='logo' className={classes.image} /></Link></div>
        <Grid container spacing={0} className='pb-30'>
          <Grid item xs={4} >
            <div>
              <div className={classes.bold} onClick={()=>history.push('/about')}>About</div>
              <div className={classes.bold} onClick={()=>history.push('/#feature')}>Feature</div>
              <div className={classes.bold} onClick={()=>history.push('/#pricing')}>Pricing</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <div className={classes.bold} onClick={()=>history.push('/terms')}>Terms & Conditions</div>
              <div className={classes.bold} onClick={()=>history.push('/policy')}>Privacy Policy</div>
              <div className={classes.bold} onClick={()=>history.push('/disclaimer')}>Disclaimer</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <Button 
               onClick={()=>handleMoveToStart()}
              className={cx('center-center', classes.home_btn)}>{"7-Day Free Trial"} &nbsp;
              <HiOutlineArrowRight/></Button>
              <div>
                <IconButton>
                 <a href='https://twitter.com/WickPredict' target='_blank' >
                  <img src={icon_twitter} alt=""/></a>
                </IconButton>
                <IconButton>
                <a href='https://www.facebook.com/wickpredict' target='_blank'>
                  <img src={icon_facebook} alt=""/></a>
                </IconButton>
                <IconButton>
                <a href='https://www.linkedin.com/company/wickpredict/' target='_blank'>
                  <img src={icon_linkedin} alt=""/></a>
                </IconButton>
                <IconButton>
                <a href='https://www.instagram.com/wickpredict/' target='_blank'>
                  <img src={icon_instgram} alt=""/></a>
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.footerBottom}>
          <Grid item xs={8} className='center-start'>© 2023. All Rights Reserved by Wickpredict</Grid>
          {/* <Grid item xs={4}>
            <div className="center-start">
              <img src={footer_lock} alt="lock"/>
              <div>&nbsp;Powered by&nbsp;</div>
              <img src={footer_stripe} alt="stripe"/>  
            </div>
          </Grid> */}
          <Grid item xs={4}>
            <div className={classes.centerStartColumn}>
              <div><a className={classes.link} href="mailto:Support@wickpredict.io">Support@wickpredict.io</a></div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Footer
