import React, { useState, useMemo, useEffect } from 'react'
import {
    LinearProgress
} from '@material-ui/core'
import { Space, Table, Tag } from 'antd'
import useStyles from './styles'
import * as cx from 'classnames'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { IoMdAlert } from 'react-icons/io'
import { FaRegCopy } from 'react-icons/fa'
import { AiFillWindows } from 'react-icons/ai'
import { AiFillApple } from 'react-icons/ai'
import { SiLinux } from 'react-icons/si'
import { IoIosArrowForward } from 'react-icons/io'
import { AiOutlineDownload } from 'react-icons/ai'
import paymentAsset from 'assets/images/images/payment.png'
import regLock from 'assets/images/images/reg-lock.svg'
import regStripe from 'assets/images/images/reg-stripe.svg'
import { FiCreditCard } from 'react-icons/fi'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { subscriptionInfoSelector, last4Selector, invoicesSelector }
    from 'redux/modules/subscribe/selectors'
import { isSubscribeLoadingSelector } from 'redux/modules/global/selectors'
import { createStructuredSelector } from 'reselect'
import { setIsSubscribeLoading } from 'redux/modules/global/actions'
import { getInvoices } from 'redux/modules/subscribe/actions'
import { refresh } from 'redux/modules/auth/actions'
import {
    getSubscriptionInfo,
} from 'redux/modules/subscribe/actions'
import moment from 'moment'

const Invoices = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const [invoices, setInvoices] = useState([])

    useEffect(() => {
        if (props.currentUser != null) {
            props.setIsSubscribeLoading({ isLoading: true })
            props.getInvoices({
                body: { 'userId': props.currentUser.id },
                success: (() => {
                    props.setIsSubscribeLoading({ isLoading: false })
                })
            })
        }
    }, [props.currentUser, location])

    useEffect(() => {
        setInvoices(props.invoices ?? [])
    }, [props.invoices])

    const refreshUser = async () => {
        props.setIsSubscribeLoading({ isLoading: true })
        await props.refresh({
            success: ({ data }) => {
                props.setIsSubscribeLoading({ isLoading: false })
            },
        })
    }
    const columns = [
        {
            title: 'Date',
            dataIndex: 'created',
            key: 'created',
            render: (_, record) => <a href={record.hosted_invoice_url} className="invoice-link" target="_blank">
                {moment(record.created * 1000).format('MMM DD YYYY')}</a>
        },
        {
            title: 'Amount(usd)',
            dataIndex: 'amount_paid',
            key: 'amount_paid',
            render: (_, record) => <div>${record.amount_paid / 100}</div>
        },
        {
            title: 'Payment Method',
            key: 'action',
            render: (_, record) => (
                <div>Credit Card</div>
            ),
        },
    ]

    return (
        <>
            <div>
                <div className={cx(classes.title, 'pb-10')}>Invoices</div>
                {props.isSubscribeLoading ? <div className='linear-height'>
                    <LinearProgress />
                    <div className={classes.loading}></div>
                </div> : <div className='linear-height-border'>
                </div>}
                <div className='table-responsive'>
                <Table columns={columns} dataSource={invoices} className="antd-Tbl" />
                </div>
                <div>* The invoice link will take users to the stripe invoice link. </div>
            </div>
        </>
    )
}

Invoices.propTypes = {
    currentUser: PropTypes.any,
    setIsSubscribeLoading: PropTypes.func,
    getSubscriptionInfo: PropTypes.func,
    getInvoices: PropTypes.func,
    refresh: PropTypes.func,
    isSubscribeLoading: PropTypes.any,
    last4: PropTypes.any
}

const actions = {
    setIsSubscribeLoading,
    getSubscriptionInfo,
    getInvoices,
    refresh,
}

const selector = createStructuredSelector({
    isSubscribeLoading: isSubscribeLoadingSelector,
    currentUser: currentUserSelector,
    subscriptionInfo: subscriptionInfoSelector,
    invoices: invoicesSelector,
    last4: last4Selector
})

export default compose(connect(selector, actions))(Invoices)