import { RxDashboard } from 'react-icons/rx'
import { AiOutlineUser } from 'react-icons/ai'
import { TbFileDollar } from 'react-icons/tb'
import { FiKey } from 'react-icons/fi'
import { FiLogOut } from 'react-icons/fi'

const NavListAdmin = [
    {
        label: "Users", icon: RxDashboard, path: '/app/users', active: false
    },
    {
        label: "Admins", icon: AiOutlineUser, path: '/app/admins', active: false
    },
    {
        label: "Transactions", icon: TbFileDollar, path: '/app/transactions', active: false
    },
    {
        label: "Change Password", icon: FiKey, path: '/app/changepassword', active: false
    },
    {
        label: "Sign out", icon: FiLogOut, path: '/signin', active: false
    },
  ]

  export default NavListAdmin