import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    // marginTop: theme.spacing(8),
    // marginBottom: theme.spacing(10),
    // '@media only screen and (max-width: 600px)': {
    //   marginTop: theme.spacing(4),
    //   marginBottom: theme.spacing(5),
    // },
    paddingLeft: theme.spacing(6),
    '@media only screen and (max-width: 600px)': {
      paddingLeft: theme.spacing(3),
    },
  },
  title: {
    color: '#333333',
    fontWeight: 'bold',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '40px',
    lineHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent:'center',
    '@media only screen and (max-width: 600px)': {
      fontSize: '22px',
      lineHeight: '26px',
    }
  },
  descTitle:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '34px',
    color: '#333333',
    '@media only screen and (max-width: 600px)': {
      fontSize: '24px',
      lineHeight: '36px',
    }
  },
  questionTitle:{
    color: '#333333',
    backgroundColor:'#DDDDDD',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent:'space-between',
    padding: '6px 28px',
    cursor:'pointer',
    // marginBottom:'10px',
    width: '100%',
    '@media only screen and (max-width: 600px)': {
      fontSize: '16px',
      lineHeight: '24px',
    }
  },

  questionDesc:{
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#333333',
    padding: '10px 10px 0px',
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '16px',
    }
  },

  questionDescBold:{
    fontWeight: 700,
  },

  quiz:{
    textAlign:'left'
  },
  logoImg:{
    width:'inherit',
    '@media only screen and (max-width: 1200px)': {
      width:'250px'
    },
  },
}))
