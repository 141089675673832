import React,{ useState, memo } from 'react'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { useMediaQuery } from 'react-responsive'

const ConceptSection = (props) => {
  const classes = useStyles()
  const isMobile = useMediaQuery({ maxWidth: 600 })
  

  return (
    <Container className={classes.root}>
      <div className={classes.title}>{props.title}</div>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.description}>
          {props.description}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ConceptSection
