import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    // background: 'linear-gradient(165deg, #0052FF 0%, #FF00D0 100%)',
    // clipPath: 'polygon(0 10%, 100% 0%, 100% 100%, 0% 100%)',
    background: '#009348',
    color: 'white',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    padding: '10px 29px'

  },

  logoImage: {
    userSelect: 'none',
    marginBottom: theme.spacing(2),
    width: '157px',
  },

  home_btn:{
    background: '#FFFFFF',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: '4px',
    border: '1px solid #009348',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#009348',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '7px',
    paddingBottom: '5px',
    '&:hover': {
      background:'white',
      color:'#009348',
    },
  },
  
  image: {
    userSelect: 'none',
    marginTop: theme.spacing(2),
    margin: theme.spacing(0.5),
  },
  bold: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '27px',
    textAlign:'left'
  },
  divider: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    marginLeft: 'calc((100% - 40px) / 2 )',
    width: '40px',
    height: '1px',
    background: 'white',
  },
  onePage: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
  },
  link: {
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      color: '#DEDEDE',
      cursor: 'pointer',
      textDecoration: 'underline',
    }
  },
  socialLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '8px'
  },
  centerStartColumn:{
    display: 'flex',
    flexDirection: 'column',
    alignItems:'flex-start',
    justifyContent:'center',
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight:'500'
  }
}))
