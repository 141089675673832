import React, { useState, useEffect, useRef } from 'react'
import './Home.scss'
import { Mobile, Default } from 'containers/ResponseLayout'
import { Header, MobileHeader } from 'containers/Header'
import { LookingForSection } from 'containers/LookingForSection'
import { LookingForSectionTwo } from 'containers/LookingForSectionTwo'
import { LookingForSectionThree } from 'containers/LookingForSectionThree'
import { LookingForSectionFour } from 'containers/LookingForSectionFour'
import { LookingForSectionFive } from 'containers/LookingForSectionFive'
import { LookingForSectionSix } from 'containers/LookingForSectionSix'
import { ConceptSection } from 'containers/ConceptSection'
import { HomeDetailSection } from 'containers/HomeDetailSection'
import { HomeDetailSectionTwo } from 'containers/HomeDetailSectionTwo'

import { Footer, MobileFooter } from 'containers/Footer'
import { BackgroundLine } from 'components/BackgroundLine'
import { useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import 'react-modal-video/scss/modal-video.scss'
import Viewer from 'react-viewer'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { createStructuredSelector } from 'reselect'
import { refresh } from 'redux/modules/auth/actions'

const Home = (props) => {
  const location = useLocation()
  const history = useHistory()
  const [isLandingHeader, setIsLandingHeader] = useState(true)
  const [isOpen, setOpen] = useState(false)
  const [videoIndex, setVideoIndex] = useState(0)
  const [currentImage, setCurrentImage] = useState(
    "https://firebasestorage.googleapis.com/v0/b/flirty-db9bc.appspot.com/o/other"
    + "%2Fbinance-earn-1.png?alt=media&token=80fe6992-4aab-40e3-a88e-2fb89f4b4914")
  const [visible, setVisible] = useState(false)

  const refAbout = useRef(null)
  const refFeature = useRef(null)
  const refPricing = useRef(null)

  const title = [
    "WickPredict is a Trading tool & an Educational tool!",
    "Robust Database with over 70 Industry Recognized Patterns"
  ]
  const description = [
    "Use Wick Predict to recognize and take action quickly " +
    "before the crowd. And when you’re not trading and want to learn, " +
    "Wick Predict is your virtual flashcard deck for memorizing the " +
    "most widely used technical analysis chart patterns.",
    "The Wick Predict database contains over 70 short-form " +
    "(1-5 candlestick) patterns. New industry-recognized patterns" +
    " are being added regularly. With Wick Predict, you’ll never miss" +
    " recognizing a technical chart pattern again."
  ]

  const frameList = [
    'https://wickpredict.s3.amazonaws.com/Top10/detail_01.png',
    'https://wickpredict.s3.amazonaws.com/Top10/detail_02.png',
    'https://wickpredict.s3.amazonaws.com/Top10/detail_03.png',
    'https://wickpredict.s3.amazonaws.com/Top10/detail_04.png',
    'https://wickpredict.s3.amazonaws.com/Top10/detail_05.png',
    'https://wickpredict.s3.amazonaws.com/Top10/detail_06.png',
    'https://wickpredict.s3.amazonaws.com/Top10/detail_07.png',
    'https://wickpredict.s3.amazonaws.com/Top10/detail_08.png',
    'https://wickpredict.s3.amazonaws.com/Top10/detail_09.png',
  ]
  const detailList = [
    'assets/images/images/detail_01.png',
    'assets/images/images/detail_02.png',
    'assets/images/images/detail_03.png',
    'assets/images/images/detail_04.png',
    'assets/images/images/detail_05.png',
    'assets/images/images/detail_06.png',
    'assets/images/images/detail_07.png',
    'assets/images/images/detail_08.png',
    'assets/images/images/detail_09.png']

  const youtubeList = [
    'https://wickpredict.s3.amazonaws.com/Top10/Wick+Predict+Main+Promo+60.mp4',
    'https://wickpredict.s3.amazonaws.com/Top10/Wick+Predict+Explainer.mp4',
    'https://wickpredict.s3.amazonaws.com/Top10/Wick+Predict+Pattern+Recognition.mp4',
    'https://wickpredict.s3.amazonaws.com/Top10/Wick+Predict+Learning+Mode.mp4'
  ]

  useEffect(() => {
    if (location.hash == '#about') {
      refAbout.current.scrollIntoView()
    } else if (location.hash == '#feature') {
      refFeature.current.scrollIntoView()
    } else if (location.hash == '#pricing') {
      refPricing.current.scrollIntoView()
    }
  }, [location])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    const len = window.location.search.length
    const value = window.scrollY > 50 ? false : true
    len === 0 && setIsLandingHeader(value)
  }

  const moveToStart = async (value) => {
    if (props.currentUser != null) {
      await props.refresh({
        success: ({ data }) => {
          history.push('/app')
        },
      })
    } else {
      if (value != undefined) {
        history.push('/start?mode=year')
      } else {
        history.push('/start')
      }
    }
  }

  return (
    <>
      <Mobile>
        <MobileHeader isLandingPage={isLandingHeader} />
        <div id='detail-description'>
          <div className='home-header-section'>
            <HomeDetailSection
              moveToStart={moveToStart}
              showVideo={() => {
                setVideoIndex(0)
                setOpen(true)
              }} />
          </div>
        </div>
        <div ref={refAbout} className='pt-60'>
          <div>
            <HomeDetailSectionTwo showVideo={() => {
              setVideoIndex(1)
              setOpen(true)
            }} />
          </div>
        </div>
        <div className='home-pattern-section' ref={refFeature}>
          <div>
            <LookingForSection showVideo={() => {
              setVideoIndex(2)
              setOpen(true)
            }} />
          </div>
        </div>
        <div className='home-detail-section'>
          <div id='detail-description'>
            <ConceptSection title={title[0]} description={description[0]} />
          </div>
        </div>
        <div className='home-pattern-section'>
          <div>
            <LookingForSectionTwo showVideo={() => {
              setVideoIndex(3)
              setOpen(true)
            }} />
          </div>
        </div>

        <div className='home-detail-section'>
          <div id='detail-description'>
            <ConceptSection title={title[1]} description={description[1]} />
          </div>
        </div>

        <div className='home-pattern-section'>
          <div>
            <LookingForSectionThree frameList={frameList} detailList={detailList}
              showImage={(index) => {
                setCurrentImage(frameList[index])
                setVisible(true)
              }} />
          </div>
        </div>
        <div className='home-manager-section'>
          <div>
            <LookingForSectionFour />
          </div>
        </div>
        <div className='home-detail-section' ref={refPricing}>
          <div>
            <LookingForSectionFive moveToStart={moveToStart} />
          </div>
        </div>
        <div className='home-pattern-section'>
          <div>
            <LookingForSectionSix />
          </div>
        </div>
        <MobileFooter moveToStart={moveToStart} />

        <Viewer
          visible={visible}
          onClose={() => { setVisible(false) }}
          images={[{ src: currentImage, alt: '' }]}
        />
      </Mobile>

      <Default>
        <BackgroundLine>
          <Header isLandingPage={isLandingHeader} />
          <div className='home-header-section'>
            <div id='detail-description'>
         
              <HomeDetailSection
                moveToStart={moveToStart}
                showVideo={() => {
                  setVideoIndex(0)
                  setOpen(true)
                }} />
            </div>
          </div>
          <div ref={refAbout} className='pt-30'>
            <div>
              <HomeDetailSectionTwo showVideo={() => {
                setVideoIndex(1)
                setOpen(true)
              }} />
            </div>
          </div>
          <div className='home-pattern-section' ref={refFeature}>
            <div>
              <LookingForSection showVideo={() => {
                setVideoIndex(2)
                setOpen(true)
              }} />
            </div>
          </div>
          <div className='home-detail-section'>
            <div id='detail-description'>
              <ConceptSection title={title[0]} description={description[0]} />
            </div>
          </div>
          <div className='home-pattern-section'>
            <div>
              <LookingForSectionTwo showVideo={() => {
                setVideoIndex(3)
                setOpen(true)
              }} />
            </div>
          </div>
          <div className='home-detail-section'>
            <div id='detail-description'>
              <ConceptSection title={title[1]} description={description[1]} />
            </div>
          </div>
          <div className='home-pattern-section'>
            <div>
              <LookingForSectionThree frameList={frameList} detailList={detailList} showImage={(index) => {
                setCurrentImage(frameList[index])
                setVisible(true)
              }} />
            </div>
          </div>
          <div className='home-manager-section'>
            <div>
              <LookingForSectionFour />
            </div>
          </div>
          <div ref={refPricing}>
            <div className='home-detail-section'>
              <div>
                <LookingForSectionFive moveToStart={(value) => moveToStart(value)} />
              </div>
            </div>
          </div>
          <div className='home-pattern-section'>
            <div>
              <LookingForSectionSix />
            </div>
          </div>
          <Footer moveToStart={(value) => moveToStart(value)} />
          {visible && <div className='custom-modal'>
            <div className='custom-modal-content '>
              <button class="custom-modal-close-btn" onClick={() => {
                setVisible(false)

              }} aria-label="Close the modal by clicking here">
                <i className="custom-modal-close-icon"></i></button>
              
              <img src={currentImage} ></img>
            </div>
          </div>
          }

        </BackgroundLine>
      </Default>
    </>
  )
}

Home.propTypes = { 
  currentUser: PropTypes.any,
  refresh: PropTypes.func,
}

const actions = {
  refresh,
}

const selector = createStructuredSelector({
  currentUser: currentUserSelector,
})
export default compose(connect(selector, actions))(Home)
