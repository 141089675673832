import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    // marginTop: theme.spacing(10),
    // marginBottom: theme.spacing(10),
    // '@media only screen and (max-width: 600px)': {
    //   marginTop: theme.spacing(5),
    //   marginBottom: theme.spacing(5),
    // }
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    '@media only screen and (max-width: 600px)': {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  title: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent:'center',
    paddingBottom: '30px',
    '@media only screen and (max-width: 600px)': {
      fontSize: '26px',
      lineHeight: '36px',
      paddingBottom: '15px',
    }
  },
  descTitle:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#333333',
  },
  description:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#333333',
  },
  videoCover:{
    width:'100%',
    height:'320px',
    position: 'relative',
    '@media only screen and (max-width: 1200px)': {
      height:'250px',
    },
  },
  logoVideo:{
    padding:'20px 0px',
  },
  managerAvatar:{
    borderRadius:'100px',
    height:'120px'
  },
  managerCard:{
    padding: '44px 20px',
    gap: '10px',
    width: '300px',
    height: '346px',
    background: '#FBF7EE',
    borderRadius: '10px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column'
  },
  managerName:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '34px',
    /* identical to box height */
    textAlign: 'center',
    /* Main color */
    color: '#333333',
  },
  managerStatus:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '13px',
    textAlign: 'center',
    /* Dark */
    color: '#333333',
  },
  managerDetail:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    /* Dark */
    color: '#333333',
  },
  videoBox:{
    width:'100%',
    height:'100%',
    background: '#FFFFFF',
    border: '3px solid #009348',
    boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.25)',
    borderRadius: '15px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    color: '#009348',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
  },
  videoPlay:{
    position:'absolute',
    top:0,
    width:'100%',
    height:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
  },
  logoImg:{
    width:'inherit',
    '@media only screen and (max-width: 1200px)': {
      width:'250px'
    },
  },
}))
