import React, { useState } from 'react'
import useStyles from './Form.styles'
import {
  Container,
  CircularProgress,
  Button
} from '@material-ui/core'
import { CustomInput } from 'components/CustomInput'
import { CustomButton } from 'components/CustomButton'
import { resetPasswordTokenSelector } from 'redux/modules/global/selectors'
import { resetPassword } from 'redux/modules/auth/actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import PropTypes from 'prop-types'
import { CustomAlert } from 'components/CustomAlert'
import * as cx from 'classnames'
import { IoMdAlert } from 'react-icons/io'
import { AiOutlineEye } from 'react-icons/ai'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'

const Form = ({
  resetPassword,
  resetPasswordToken,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [isWaiting, setIsWaiting] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [alertMode, setAlertMode] = useState('error')
  const [showPass, setShowPass] = useState(false)
  const [showResend, setShowResend] = useState(false)
  
  const [input, setInput] = useState({
    password: '',
    confirmPassword: ''
  })

  const [error, setError] = useState({
    password: '',
    confirmPassword: ''
  })

  const onInputChange = e => {
    const { name, value } = e.target
    setInput(prev => ({
      ...prev,
      [name]: value
    }))
    validateInput(e)
  }

  const validateInput = e => {
    let { name, value } = e.target
    setError(prev => {
      const stateObj = { ...prev, [name]: "" }

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password."
          } else if (value.length < 8) {
            stateObj[name] = "Password lenght needs to be over 8"
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] = "Password and Confirm Password does not match."
          } else {
            stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword
          }
          break

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password."
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match."
          }
          break

        default:
          break
      }
      return stateObj
    })
  }

  const handleSavePassword = async (event) => {
    event.preventDefault()
    console.log("input.password==0:",input.password)
    if (input.password.length > 8 && input.password == input.confirmPassword) {
      setIsWaiting(true)
      await resetPassword({
        params: { token: resetPasswordToken },
        body: { password: input.password },
        success: ({ data }) => {
          console.log("set password success:", data)
          setIsWaiting(false)
          setAlertOpen(true)
          setAlertText('Set Password Success!')
          setAlertMode('success')
          setTimeout(()=>{
            history.push('/signin')
          },1000)
        },
        fail: (err) => {
          console.log("set passworderr:", err)
          console.log("set passworderr.message:", err.message)
          setAlertOpen(true)
          setIsWaiting(false)
          if(err.status==401){
            setAlertText("Link has expired.")
            setShowResend(true)
          }
          else if(err.message==undefined){
            setShowResend(true)
            setAlertText("Failed to reset password, please request again")
          }else{
            setAlertText(err.message)
          }
          setAlertMode('error')
        }
      })
    }else{
      setAlertOpen(true)
      setAlertText("Password needs to include 5 characters at least")
      setAlertMode('error')
    }
  }

  return (
    <>
      <div className={classes.root}>
        <Container>
          <form className="password-form" onSubmit={handleSavePassword}>
            <div className={cx(classes.title,'pb-20')}>Set Your Secure Password</div>
            {/* <div className={classes.description}>
              <div>
                <IoMdAlert />
              </div>
              <div className='pl-10'>
                Your order has been processed!<br />
                The next step is to set a strong account
                password.
              </div>
            </div> */}
            <div className='position-relative'>
              <input
                type={showPass?'text':"password"}
                name="password"
                placeholder='Enter Password'
                value={input.password}
                onChange={onInputChange}
                onBlur={validateInput}></input>
                <div className={classes.posAlert} 
                onClick={()=>{setShowPass(!showPass)}}>{showPass?<AiOutlineEyeInvisible/>:<AiOutlineEye/>}</div>
            </div>
            {error.password && <div className='errText'>{error.password}</div>}
            <div className='pb-10'></div>
            <div>
              <input
                type={showPass?'text':"password"}
                name="confirmPassword"
                placeholder='Enter Confirm Password'
                value={input.confirmPassword}
                onChange={onInputChange}
                onBlur={validateInput}></input>
            </div>
            {error.confirmPassword && <div className='errText'>{error.confirmPassword}</div>}
            <div className='pb-20'></div>
            <div className={classes.wrapper}>
              {!isWaiting && <Button type="submit" className={cx(classes.home_btn)}>
                Save new password</Button>}
              {isWaiting && <Button className={cx(classes.home_btn, 'green_buttonProgress')} disabled>
                <CircularProgress size={24}/></Button>}
            </div>
            {showResend?<div className={cx('pt-20 pl-10 pr-10',classes.resendText)}>
              Your token has expired. Please reset your password from &nbsp;
              <span className={classes.resendLink} onClick={()=>
                history.push('/forgot-password')}>the forgot password page.</span>
                </div>:<div></div>}
          </form>
        </Container>
        <div className={classes.label3}></div>
      </div>
      <CustomAlert
        isOpen={alertOpen}
        type={alertMode}
        text={alertText}
        onClose={() => setAlertOpen(false)}
      />
    </>
  )
}

Form.propTypes = {
  resetPassword: PropTypes.func,
  resetPasswordToken: PropTypes.any,
}

const actions = {
  resetPassword,
}

const selector = createStructuredSelector({
  resetPasswordToken: resetPasswordTokenSelector
})

export default compose(connect(selector, actions))(Form)
