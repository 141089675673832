import React, { useState, useMemo } from 'react'
import {
    Grid,
    Button,
    IconButton
} from '@material-ui/core'
import useStyles from './styles'
import * as cx from 'classnames'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { IoMdAlert } from 'react-icons/io'
import paymentAsset from 'assets/images/images/payment.png'
import regLock from 'assets/images/images/reg-lock.svg'
import regStripe from 'assets/images/images/reg-stripe.svg'
import {
    useStripe,
    useElements,
    Elements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js"
import subMark from 'assets/images/images/subMark.svg'


const OrderCard = (props) => {

    const classes = useStyles()

    console.log("props===990:",props)

    return (
        <>
            <div className={classes.cardSummary}>
                <div className={cx(classes.titleInCard, 'pb-10')}>Order Summary</div>
                {props.isYear?<div className='center-between pb-10'>
                    <div className={classes.sumPlan1}>Annual Plan</div>
                    <div className={classes.sumPrice}>$78.98/year</div>
                </div>:<div className='center-between pb-10'>
                    <div className={classes.sumPlan1}>Monthly Plan</div>
                    <div className={classes.sumPrice}>$9.98/month</div>
                </div>}
                <div className={cx('pb-10', classes.sumPlan2)}>(1 Device/ 7 day free Trial)</div>
                <hr className={classes.lineTwo}></hr>
                <div className={cx(classes.titleInCard, 'pt-10')}>Product and Offer Details</div>
                <div className='pb-10'>
                Upon signing up for the free trial of Wick Predict,
                 you’ll enjoy full access to the Wick Predict application. As your trial period
                  comes to an end, we'll send you a friendly reminder to enter your payment details
                   if you wish to continue using Wick Predict by purchasing a subscription. <br/><br/>
                    After the trial, the first term is priced at $9.98 or you may choose to
                     pay for a full 1-year subscription at $78.98 (saving 35%), with subsequent
                      automatic renewals monthly or yearly depending on the subscription you choose.
                       You have the flexibility to upgrade/downgrade or cancel your subscription at any time.</div>
                <hr className={classes.lineOne}></hr>
                <div className='center-between pt-10'>
                    <div className={classes.titleInCard}>Order total</div>
                    <div>$0.00</div>
                </div>
            </div>
        </>
    )
}

export default OrderCard