import React,{ useState, memo } from 'react'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { useMediaQuery } from 'react-responsive'
import binanceEarn1 from 'assets/images/images/binance-earn-1.png'
import logo_video from 'assets/images/images/logo_video.png'
import play_icon from 'assets/images/Icon/play.svg'
import { IconButton } from '@material-ui/core'

const LookingForSection = (props) => {
  const classes = useStyles()
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const [isOpen, setOpen] = useState(false)

  return (
    <Container className={classes.root}>
      <div className={classes.title}>Pattern Detection Highlights Patterns in Real Time!</div>
      <Grid container spacing={3} className="center-between">
        <Grid item xs={isMobile ? 12 : 6} className={classes.description}>
          <div className='pb-20'>
            <span className={classes.descTitle}>Wick Predict can easily spot Technical Analysis 
            Patterns on any screen for almost any chart,</span> no matter 
            the asset class! Wick Predict will even find patterns on Google Images!
          </div>
          <div className='pb-20'>
            Each pattern is ranked in detection probability. 
            Wick Predict show you a comparison overlay, 
            probability of a match and explains each pattern in detail.
          </div>
          {!isOpen &&
          <div className={classes.videoCover}>
            <div className={classes.videoBox}>
              <div>Pattern Recognition</div>
              <div className={classes.logoVideo}><img className={classes.logoImg} src={logo_video} alt=''/></div>
              <div>&nbsp;</div>
            </div>
            <div className={classes.videoPlay} onClick={()=>props.showVideo()}>
            <IconButton onClick={() =>   setOpen(true)}>
                  <img src={play_icon} alt='p' />
                </IconButton>
            </div>
          </div>
          }
          {isOpen &&  <div className={classes.videoCover}>
              <div className={isMobile ? classes.videoBoxMobile : classes.videoBox}>
              <video autoplay="false" name="media" controls="true" className={classes.videoPlayer2}>
                <source src="https://wickpredict.s3.amazonaws.com/Top10/Wick+Predict+Pattern+Recognition.mp4" 
                type="video/mp4"/></video>
                </div>  
                </div>
            }
        </Grid>
        <Grid item xs={isMobile ? 12 : 6}>
          <div>
            <img src={binanceEarn1} style={{width: "-webkit-fill-available"}} alt=''/>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default LookingForSection
