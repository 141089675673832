import React, { useState, useMemo, useEffect } from 'react'
import {
    Grid,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress
} from '@material-ui/core'
import useStyles from './styles'
import * as cx from 'classnames'
import { IoMdAlert } from 'react-icons/io'
import paymentAsset from 'assets/images/images/payment.png'
import paymentAsset2 from 'assets/images/images/payment02.png'
import regLock from 'assets/images/images/reg-lock.svg'
import regStripe from 'assets/images/images/reg-stripe.svg'
import {
    useStripe,
    useElements,
    Elements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js"
import { FiCreditCard } from 'react-icons/fi'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { subscriptionInfoSelector, last4Selector }
    from 'redux/modules/subscribe/selectors'
import { subscribeDetailSelector } from 'redux/modules/subscribe/selectors'
import { isSubscribeLoadingSelector } from 'redux/modules/global/selectors'
import { createStructuredSelector } from 'reselect'
import { setIsSubscribeLoading } from 'redux/modules/global/actions'
import { refresh } from 'redux/modules/auth/actions'
import {
    getSubscriptionInfo, getSubscribeDetail,
    cardUpdateInit, cardUpdateFinal, cancelStripeSubscription
} from 'redux/modules/subscribe/actions'
import { roles } from 'helpers/AppConfig'
import moment from 'moment'
import { Loader } from 'components/Loader'
import useResponsiveFontSize from "containers/RegisterStart/useResponsiveFontSize"
import { authInfo, authClear } from 'helpers/localCheck'
import dashboard_a from 'assets/images/images/dashboard_a.png'
import { useMediaQuery } from 'react-responsive'

const useOptions = () => {
    const fontSize = useResponsiveFontSize()
    const optionsNumber = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "inherit",
                    "::placeholder": {
                        color: "#7A7D85"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            },
            placeholder: "Credit Card Number"
        }),
        [fontSize]
    )

    const optionsExp = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "inherit",
                    "::placeholder": {
                        color: "#7A7D85"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            },
            placeholder: "Exp (MM/YY)"
        }),
        [fontSize]
    )

    const optionsCvv = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "inherit",
                    "::placeholder": {
                        color: "#7A7D85"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            },
            placeholder: "CVV"
        }),
        [fontSize]
    )

    return [optionsNumber, optionsExp, optionsCvv]
}

const Subscriptions = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const stripe = useStripe()
    const elements = useElements()
    const options = useOptions()
    const [subscriptionInfo, setSubscriptionInfo] = useState({})
    const [subscribeDetail, setSubscribeDetail] = useState({})
    const [open, setOpen] = useState(false)
    const [openCancel, setOpenCancel] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [postCode, setPostCode] = useState("")
    const [showError, setShowError] = useState({
        status: false,
        text: ""
    })
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertText, setAlertText] = useState('')
    const isMobile = useMediaQuery({ maxWidth: 600 })

    useEffect(() => {
        if (props.currentUser != null) {
            // props.setIsSubscribeLoading({ isLoading: true })
            props.getSubscriptionInfo({
                body: { 'userId': props.currentUser.id },
                success: (() => {
                    // props.setIsSubscribeLoading({ isLoading: false })
                })
            })
        }
    }, [props.currentUser, location, props])

    useEffect(() => {
        setSubscriptionInfo(props.subscriptionInfo ?? {})
    }, [props.subscriptionInfo])

    const onClose = async () => {
        setOpen(false)
    }

    const onCloseCancel = async () => {
        setOpenCancel(false)
    }

    const inputCode = (e) => {
        if (e.target.value == "") {
            setShowError({
                status: false,
                text: ""
            })
        } else {
            if (parseInt(e.target.value) > 0) {
                setShowError({
                    status: false,
                    text: ""
                })
                setPostCode(e.target.value)
            } else {
                setShowError({
                    status: true,
                    text: "Please Input Number"
                })
            }
        }
    }

    const cancelSubscription = async (event) => {
        props.setIsSubscribeLoading({ isLoading: true })
        props.cancelStripeSubscription({
            body: { userId: props.currentUser.id },
            success: () => {
                props.setIsSubscribeLoading({ isLoading: false })
                refreshUser()
            }
        })
    }

    const refreshUser = async () => {
        props.setIsSubscribeLoading({ isLoading: true })
        await props.refresh({
            success: ({ data }) => {
                props.setIsSubscribeLoading({ isLoading: false })
            },
        })
    }

    const paymentSubmit = async (event) => {
        event.preventDefault()
        if (!stripe || !elements) {
            return
        }

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement)
        })
        if (payload.error != undefined) {
            setShowError({
                status: true,
                text: payload.error.message
            })
        } else {
            if (postCode.length > 0) {
                var paymentMethodId = payload.paymentMethod.id
                const { id, email } = authInfo().user
                props.cardUpdateInit({
                    body: {
                        subscriptionId: subscriptionInfo.subscriptionId,
                        customerId: subscriptionInfo.customerId,
                        paymentMethodId: paymentMethodId,
                        userId: id,
                        last4: payload.paymentMethod.card.last4
                    },
                    success: ({ data }) => {
                        setOpen(false)
                        refreshUser()
                    },
                    fail: (err) => {
                        if (err.status === 400) {
                            const error = err.data.message
                            if (error.includes('email')) {
                                setShowError({
                                    status: true,
                                    text: error.replace(`"email"`, 'Email')
                                })
                            }
                        } else {
                            setAlertText(err.data.message)
                            setAlertOpen(true)
                        }
                    }
                })
            } else {
                setShowError({
                    status: true,
                    text: "Please Input Postcode"
                })
            }
        }
    }

    return (
        <>
            {props.currentUser.role == roles[0] ?
                <div className={classes.cardSummary}><Grid container>
                    <Grid item md={7} xs={12} className="between-start">
                        <div className={cx(classes.cardTitle)}>Miss WickPredict?</div>
                        <div className={cx(classes.cardTitle, 'pb-30')}>Get a subscription</div>
                        <Button className={classes.home_btn_buy}
                            onClick={() => { history.push('subscriptionplans') }}>
                            Buy a New Subscription</Button>
                    </Grid>
                    <Grid item md={5} xs={12} className="center-center">
                        
                    </Grid>
                </Grid></div>
                : <div>
                    <div className={cx(classes.title, 'pb-10')}>My Subscription</div>
                    {props.isSubscribeLoading ? <div className='linear-height'>
                        <LinearProgress />
                        <div className={classes.loading}></div>
                    </div> : <div className='linear-height-border'>
                    </div>}
                    <div className={classes.managerCard}>
                        <div>
                            <div className={classes.managerTitle}>Subscription</div>
                            <div className={classes.managerDetail}>ID <span>{
                                subscriptionInfo.start_date}</span></div>
                        </div>
                        <div>
                            <div className={classes.managerDetail}>Current Plan</div>
                            <div className={classes.managerDetail}>{
                                subscriptionInfo.planType == roles[1]
                                    ? "$9.98 per month" : subscriptionInfo.planType == roles[2] ?
                                        "$78.98 per year" : "Loading"}</div>
                            <div className={classes.managerDetail}>
                                You will next be charged on {subscriptionInfo.current_period_end == undefined ?
                                    moment().format("MMM Do YYYY")
                                    : moment.unix(subscriptionInfo.current_period_end).format("MMM Do YYYY")}
                            </div>
                        </div>
                        <div className={isMobile ? 'center-column' : 'center-center'}>
                            <Button className={cx(classes.home_btn)}
                                onClick={() => { history.push('subscriptionplans') }}>Manage Plan</Button>
                            <div className='w-10 pt-10'></div>
                            <Button className={cx(classes.home_btn_white)}
                                onClick={() => setOpenCancel(true)}>
                                Cancel Subscription</Button>
                        </div>
                        <div>
                            <div className={cx(classes.managerTitle, 'pb-5')}>Payment Method</div>
                            <div className={(classes.cardEnd, 'center-start')}>
                                <FiCreditCard /><div>&nbsp;&nbsp;Ending in {subscriptionInfo.last4}</div>
                            </div>
                            <div className='pb-20'></div>
                            <Button className={(classes.home_btn)}
                                onClick={() => {
                                    // cardUpdateInit()
                                    setOpen(true)
                                }}>Update Credit Card</Button>
                        </div>
                        <div>
                            <div className={classes.managerDetailUnder}
                                onClick={() => { history.push('invoices') }}>View payment history</div>
                            {/* <div className={classes.managerDetailUnder}>Update payment method</div> */}
                        </div>
                    </div>
                </div>}
            <Dialog open={open} onClose={onClose}>
                {isLoading && <Loader />}
                <DialogContent >
                    <form onSubmit={paymentSubmit}>
                        <div className='center-start pb-10 pt-10'>
                            <img src={!isMobile ? paymentAsset : paymentAsset2} className='img-100' alt="" />
                        </div>
                        <div className='pb-10 pt-10'>
                            <CardNumberElement
                                options={options[0]}
                                onReady={() => {
                                    console.log("CardNumberElement [ready]")
                                }}
                                onChange={event => {
                                    console.log("CardNumberElement [change]", event)
                                }}
                                onBlur={() => {
                                    console.log("CardNumberElement [blur]")
                                }}
                                onFocus={() => {
                                    console.log("CardNumberElement [focus]")
                                }}
                            />
                        </div>
                        <Grid container spacing={2} className='pb-10'>
                            <Grid item xs={12} md={6} className={classes.mpadding}>
                                <CardExpiryElement
                                    options={options[1]}
                                    onReady={() => {
                                        console.log("CardNumberElement [ready]")
                                    }}
                                    onChange={event => {
                                        console.log("CardNumberElement [change]", event)
                                    }}
                                    onBlur={() => {
                                        console.log("CardNumberElement [blur]")
                                    }}
                                    onFocus={() => {
                                        console.log("CardNumberElement [focus]")
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='position-relative'>
                                    <CardCvcElement
                                        options={options[2]}
                                        onReady={() => {
                                            console.log("CardNumberElement [ready]")
                                        }}
                                        onChange={event => {
                                            console.log("CardNumberElement [change]", event)
                                        }}
                                        onBlur={() => {
                                            console.log("CardNumberElement [blur]")
                                        }}
                                        onFocus={() => {
                                            console.log("CardNumberElement [focus]")
                                        }}
                                    />
                                    <div className={classes.posAlert}><IoMdAlert size={20} /></div>
                                </div>
                            </Grid>
                        </Grid>
                        <div className='pb-10'><input className={classes.inputField}
                            placeholder='Zip/Postal code' type='text' inputMode='numeric' maxLength={6}
                            name="zipCode" onChange={inputCode} /></div>
                        <div className={cx(classes.errorText, 'pl-10')}>{
                            showError.status ? showError.text : ""}</div>
                        <Grid className='pb-20 pl-10 pt-10'>
                            <div className="center-start">
                                <img src={regLock} alt="lock" />
                                <div className={classes.poweredby}>&nbsp;Powered by&nbsp;</div>
                                <img src={regStripe} alt="stripe" />
                            </div>
                        </Grid>
                        <Grid className='pb-10 center-center'>
                            <Button className={classes.home_btn_white} onClick={onClose} color='primary'>
                                Close
                            </Button>
                            <div className='w-10'></div>
                            <Button className={classes.home_btn} type="submit" onClick={paymentSubmit}
                                color='primary'>Submit</Button>
                        </Grid>
                        <div className='center-between pt-10 pb-10'>
                        </div>
                    </form>
                </DialogContent>
            </Dialog >

            <Dialog open={openCancel} onClose={onCloseCancel}>
                <DialogContent >
                    <div className={cx('center-center pt-10 pb-20',classes.cardEnd)}>
                        Are you sure you want to cancel your subscription?
                    </div>
                    <Grid className={isMobile?'pb-10 center-column':'pb-10 center-center'}>
                        <Button className={cx(classes.home_btn_white, classes.widthJust)}
                            onClick={onCloseCancel} color='primary'>
                            Keep Plan
                        </Button>
                        <div className='w-10 pt-10'></div>
                        <Button className={cx(classes.home_btn, classes.widthJust)}
                            type="submit" onClick={()=>{
                                onCloseCancel()
                                cancelSubscription()
                            }}
                            color='primary'>Cancel Subscription</Button>
                    </Grid>

                </DialogContent>
            </Dialog >
        </>
    )
}

Subscriptions.propTypes = {
    currentUser: PropTypes.any,
    setIsSubscribeLoading: PropTypes.func,
    getSubscriptionInfo: PropTypes.func,
    getSubscribeDetail: PropTypes.func,
    cardUpdateInit: PropTypes.func,
    cardUpdateFinal: PropTypes.func,
    cancelStripeSubscription: PropTypes.func,
    refresh: PropTypes.func,
    isSubscribeLoading: PropTypes.any,
    last4: PropTypes.any
}

const actions = {
    setIsSubscribeLoading,
    getSubscriptionInfo,
    getSubscribeDetail,
    cardUpdateInit,
    cardUpdateFinal,
    refresh,
    cancelStripeSubscription
}

const selector = createStructuredSelector({
    isSubscribeLoading: isSubscribeLoadingSelector,
    currentUser: currentUserSelector,
    subscriptionInfo: subscriptionInfoSelector,
    subscribeDetail: subscribeDetailSelector,
    last4: last4Selector
})

export default compose(connect(selector, actions))(Subscriptions)