import React, { useState, memo } from 'react'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { useMediaQuery } from 'react-responsive'
import { Mobile, Default } from 'containers/ResponseLayout'
import binanceEarn1 from 'assets/images/images/binance-earn-1.png'
import logo_video from 'assets/images/images/logo_video.png'
import play_icon from 'assets/images/Icon/play.svg'
import { IconButton } from '@material-ui/core'

const LookingForSectionTwo = (props) => {
  const classes = useStyles()
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const [isOpen, setOpen] = useState(false)

  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Mobile>
          <div className={classes.title}>Learning Mode for Memorizing Patterns</div>
          <Grid item xs={12}>
          {!isOpen &&
              <div className={classes.videoCover}>
                <div className={classes.videoBox}>
                  <div>Learning Mode</div>
                  <div className={classes.logoVideo}><img className={classes.logoImg} src={logo_video} alt='' /></div>
                  <div>&nbsp;</div>
                </div>
                <div className={classes.videoPlay} onClick={() => props.showVideo()}>
                  <IconButton onClick={() =>   setOpen(true)}>
                    <img src={play_icon} alt='p' />
                  </IconButton>
                </div>
              </div>

            }
             {isOpen &&  <div className={classes.videoCover}>
              <div className={classes.videoBoxMobile}>
              <video autoplay="false" name="media" controls="true" className={classes.videoPlayer2}>
                <source src="https://wickpredict.s3.amazonaws.com/Top10/Wick+Predict+Learning+Mode.mp4" 
                type="video/mp4"/></video>
                </div>  
                </div>
            }
          </Grid>
          <Grid item xs={12} className={classes.description}>
            <div className='pb-20'>
              <span className={classes.descTitle}>In “Learning Mode” Wick Predict is
                your virtual flashcard deck for learning to recognize technical analysis
                chart patterns on your own.</span> Simply click the lightbulb icon to
              initiate flashcard mode.
            </div>
            <div className='pb-20'>
              Summon chart patterns instantly with search or filter by bullish or bearish patterns.
              Navigate pattern cards with UP and DOWN arrows and get educated.
            </div>
          </Grid>
          <Grid item xs={ 12 }>
            <div>
              <img src={binanceEarn1} style={{ width: "-webkit-fill-available" }} alt='' />
            </div>
          </Grid>
        </Mobile>


        <Default>
          <div className={classes.title}>Learning Mode for Memorizing Patterns</div>
          <Grid container spacing={3} className="center-between">
            <Grid item xs={isMobile ? 12 : 6}>
              <div>
                <img src={binanceEarn1} style={{ width: "-webkit-fill-available" }} alt='' />
              </div>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6} className={classes.description}>
              <div className='pb-20'>
                <span className={classes.descTitle}>In “Learning Mode” Wick Predict is
                  your virtual flashcard deck for learning to recognize technical analysis
                  chart patterns on your own.</span> Simply click the lightbulb icon to
                initiate flashcard mode.
              </div>
              <div className='pb-20'>
                Summon chart patterns instantly with search or filter by bullish or bearish patterns.
                Navigate pattern cards with UP and DOWN arrows and get educated.
              </div>
            {!isOpen &&
              <div className={classes.videoCover}>
                <div className={classes.videoBox}>
                  <div>Learning Mode</div>
                  <div className={classes.logoVideo}><img className={classes.logoImg} src={logo_video} alt='' /></div>
                  <div>&nbsp;</div>
                </div>
                <div className={classes.videoPlay} onClick={() => props.showVideo()}>
                  <IconButton onClick={() =>   setOpen(true)}>
                    <img src={play_icon} alt='p' />
                  </IconButton>
                </div>
              </div>

            }
             {isOpen &&  <div className={classes.videoCover}>
              <div className={classes.videoBox}>
              <video autoplay="false" name="media" controls="true" className={classes.videoPlayer2}>
                <source src="https://wickpredict.s3.amazonaws.com/Top10/Wick+Predict+Learning+Mode.mp4" 
                type="video/mp4"/></video>
                </div>  
                </div>
            }

            </Grid>
          </Grid>
        </Default>
      </Grid>
    </Container>
  )
}

export default LookingForSectionTwo
