import React, { useEffect, useState, useRef } from 'react'
import useStyles from './styles'
import tick from 'assets/lottie/Cricle_Tik.json'
import { LottiePlay } from 'components/LottiePlay'

const detail = [
  {
    title: 'Step 1 - Download & Install',
    description: `Download Wick Predict to your desktop and install the program locally to start.`
  },
  {
    title: 'Step 2 - Open Wick Predict',
    description: `Once Wick Predict is open, drag the recognition window over any chart. 
    Increase chart scale to put 1-5 candles within the detection window. `
  },
  {
    title: 'Step 3 - Click the pattern sidebar icon',
    description: `Open the sidebar to begin detection. 
    Drag the window to detect patterns in a chart dynamically in real-time. 
    Click the name of any pattern to display Direction, Characteristic and Notes of each pattern.`
  }
]

const status = [true, true, true, true]
let timerId = null
let lottieIndex = 0

const DetailDescription = () => {
  const classes = useStyles()
  const [playStatus, setPlayStatus] = useState(status)
  const isMounted = useRef(null)

  useEffect(() => {
    isMounted.current = true
    return () => {
      timerId && clearInterval(timerId)
      timerId = null
      lottieIndex = 0

      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    document.getElementById('detail-description').addEventListener('transitionend', () => {
      if (!timerId) { timerId = setInterval(lottieTime, 2000) }
    })
    const lottieTime = () => {
      lottieIndex > 3 && clearInterval(timerId)
      const tmpStatus = status.slice()
      tmpStatus[lottieIndex] = false
      setPlayStatus(tmpStatus)
      lottieIndex++
    }
  })

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>
        Intuitive & Easy to Use
      </h1>
      {
        detail.map((item, key) => {
          return (
            <div className={classes.detailItem} key={key}>
              <div className={classes.detailTitle}>
                <LottiePlay src={tick} className={classes.checkIcon} stop={playStatus[key]} />
                <div>{item.title}</div>
              </div>
              <p className={classes.detailDescription}>{item.description}</p>
            </div>
          )
        })
      }
    </div>
  )
}

export default DetailDescription
